import Vue from 'vue';
import Router from 'vue-router';
// import AuthenticationPortal from '@/views/AuthenticationPortal';
// import InvoiceAdmin from '@/views/data/admin/InvoiceAdmin';
// import CollectionAdmin from '@/views/data/admin/CollectionAdmin';
// import RecordAdmin from '@/views/data/admin/RecordAdmin';

// WorkDay
// import TimeCard from '@/views/workDay/TimeCard';
// import TimeOptions from '@/views/workDay/TimeOptions';
// import WorkDayDash from '@/views/workDay/WorkDayDash';
// import EmployerTimecards from '@/views/workDay/EmployerTimeSheets';

// import SS from '@/views/data/admin/GeneralAvailability';
// import CollectionViewer from '@/views/data/admin/CollectionViewer';
// import PrivacyPolicy from '@/views/util/privacy';

// import DownloadGenie from '@/views/util/Downloader';
// import _app from './AppData';

// Chord Wizard
import Scales from '@/components/chordWizard/Scales';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      component: Scales,
      // redirect: '/dashboard/billing',
    },
    // {
    //   path: '/chords',
    //   component: Wizard,
    // },
    // {
    //   path: '/dashboard',
    //   component: AuthenticationPortal,
    //   children: [
    //     _app.switch.workDay ? {
    //       path: 'employee',
    //       component: TimeCard,
    //       children: [
    //         {
    //           path: 'options',
    //           component: TimeOptions,
    //         },
    //         {
    //           path: 'timecard',
    //           component: TimeCard,
    //         },
    //       ],
    //     } : {},
    //     _app.switch.workDay ? {
    //       path: 'workday',
    //       component: WorkDayDash,
    //     } : {},
    //     _app.switch.workDay ? {
    //       path: 'timecards',
    //       component: EmployerTimecards,
    //     } : {},
    //     {
    //       path: 'test',
    //       component: SS,
    //     },
    //     {
    //       path: 'billing',
    //       component: InvoiceAdmin,
    //     },
    //     {
    //       path: 'billing/:id',
    //       component: InvoiceAdmin,
    //     },
    //     _app.switch.collectionAdminAccess ? {
    //       path: 'collections',
    //       component: CollectionAdmin,
    //     } : {
    //       path: 'collections',
    //       redirect: '/',
    //     },
    //     {
    //       path: 'records/:collection',
    //       component: RecordAdmin,
    //     },
    //     {
    //       path: 'records/:collection/:id',
    //       component: RecordAdmin,
    //     },
    //   ],
    // },
    // {
    //   path: '/privacy',
    //   component: PrivacyPolicy,
    // },
  ],
});


export default router;
