<template>
  <div
      id="topOfPage"
      class="chords">
    <!-- Key Information -->
    <!-- Guitar Neck Display -->
    <!-- Display neck, frets, and notes -->
    <div
        v-if="displayMode === 'Neck'">
      <b-row
          align-h="center"
          class="chords__play-button__container">
        <b-col
            cols="*"
            class="mr-auto my-auto">
          <b-btn
              id="southpawSwitch"
              @click="leftHand = !leftHand"
              variant="tertiary"
              class="mx-3">
            <b-icon
                icon="arrow-left-right"
            />
          </b-btn>
        </b-col>
        <b-col
            cols="auto"
            class="my-auto chords__key-header__text">
          <span
              v-text="nameOfKey"
              key="title"
          />

        </b-col>
        <b-col
            cols="*"
            class="ml-auto mr-3">
          <b-btn
              id="playButton"
              @click="playButton()"
              size="lg"
              class="chords__play-button"
              variant="primary">
            <b-icon
                v-if="!isPlaying"
                icon="play-circle-fill"
                scale="2.5"
            />
            <b-icon
                v-else
                icon="stop-circle-fill"
                :animation="arpDuration.label.toLowerCase()"
                scale="2.5"
            />
          </b-btn>
        </b-col>
      </b-row>
      <div
          class="neck">
        <b-row
            id="stringDisplay"
            v-for="(string, index) in stringsData"
            :key="index"
            class="neck__string">
          <div
              class="neck__string__graphic"
              :style="stringWidth(string[0].steps)"
          />
          <b-col
              v-for="(fret, index) in string"
              :key="fret.index"
              class="neck__fret"
              align="right"
              :class="classBindFret(string, index)">
              <!-- v-if="notesInKey[fret.index] || chromatic" -->
                <!-- @mouseover="playNeckSound(notesInKey[fret.index])"
                @mouseleave="stopNeckSound(notesInKey[fret.index])" -->
            <b-btn
                :id="`btn-${string}-${fret.index}`"
                :disabled="!notesInKey[fret.index] && !chromatic"
                @click="playNeckSoundClick(notesInKey[fret.index])"
                class="neck__note"
                :class="[
                  notesInKey[fret.index]
                    && notesInKey[fret.index].quality === 1
                    ? 'neck__note__highlight' : '',
                  notesInKey[fret.index]
                    && notesInKey[fret.index].notInKey
                    ? 'neck__note__transparent' : '',
                  notePlaying === fret.index ? 'neck__note__playing' : ''
                ]">
              <transition
                  mode="out-in"
                  name="fade">
                <span
                    v-if="noteDisplayText(fret)"
                    class="mx-auto"
                    v-html="noteDisplayText(fret)"
                />
              </transition>
            </b-btn>
          </b-col>
        </b-row>
        <b-row
            id="fretMarkers"
            class="neck__fret-marker__container">
            <b-col
                v-for="(fret, index) in displayFrets"
                :key="fret.index"
                align="center">
              <!-- Display Fret Markers on neck. -->
              <span
                  v-if="!leftHand && [0, 3, 5, 7, 9, 12].indexOf(index) > -1"
                  class="neck__fret-marker"
                  v-text="[0, 3, 5, 7, 9, 12].indexOf(index)> -1 ? index : null"
              />
              <!-- Account for southpaws... -->
              <span
                  v-if="leftHand && index === displayFrets.length - 1"
                  class="neck__fret-marker">
                0
              </span>
              <span
                  v-if="leftHand && index === displayFrets.length - 4"
                  class="neck__fret-marker">
                3
              </span>
              <span
                  v-if="leftHand && index === displayFrets.length - 6"
                  class="neck__fret-marker">
                5
              </span>
              <span
                  v-if="leftHand && index === displayFrets.length - 8"
                  class="neck__fret-marker">
                7
              </span>
              <span
                  v-if="leftHand && index === displayFrets.length - 10"
                  class="neck__fret-marker">
                9
              </span>
              <span
                  v-if="leftHand && index === displayFrets.length - 13"
                  class="neck__fret-marker">
                12
              </span>
            </b-col>
        </b-row>
      </div>
      <b-row
          v-if="displayMode === 'Notes'"
          class="my-2">
        <b-col
            v-for="(note, index) in key"
            :key="index"
            :class="{
              ['chords__key-header__notes--' + note.name]: note.quality,
              ['chords__key-header__notes--active-note']: synthNotes.indexOf(note.quality) > -1 || hoveringOver === note.quality,
            }"
            @click="addNoteToSynth(note.quality)"
            @mouseleave="stopSound(note)"
            @mouseover="playSound(note)">
          <span
              class="chords__key-header__notes">
              {{note.name}}
          </span>
        </b-col>
      </b-row>
    </div>
    <!-- Key Selection -->
    <b-row
        align-h="center">
        <b-col
          cols="*"
          class="chords__synth">
        <div
            class="chords__synth__label px-4">
          <div
              class="chords__synth__label-header mt-auto mb-2">
            Key Selection
          </div>
          <b-row
              align-h="center">
            <!-- Buttons: Select a letter -->
            <b-col
                cols="*"
                align="center"
                class="my-auto">
              <b-btn
                  v-for="choice in letters"
                  :key="choice"
                  :id="`button${choice}`"
                  @click="letter = choice"
                  :variant="letter === choice ? 'primary' : 'tertiary'"
                  class="chords__button__letter">
                <transition
                    mode="out-in"
                    name="fade">
                  <span
                      key="natural"
                      v-if="!sharp && !flat"
                      v-text="choice"
                  />
                  <span
                      key="sharp"
                      v-else-if="sharp">
                    {{choice}}♯
                  </span>
                  <span
                      key="flat"
                      v-else>
                    {{choice}}♭
                  </span>
                </transition>
              </b-btn>
            </b-col>
            <!-- Buttons: Flat or Sharp -->
            <b-col
                cols="*"
                align="center"
                class="my-auto">
              <b-btn
                  id="addRemoveSharp"
                  @click="buttonSharp"
                  :variant="sharp ? 'primary' : 'tertiary'"
                  class="chords__button__signs">
                <span
                    class="chords__button__signs__text">
                  ♯
                </span>
              </b-btn>
              <b-btn
                  id="addRemoveFlat"
                  @click="buttonFlat"
                  :variant="flat ? 'primary' : 'tertiary'"
                  class="chords__button__signs">
                <span
                    class="chords__button__signs__text">
                  ♭
                </span>
              </b-btn>
            </b-col>
          </b-row>
          <!-- Buttons: Major/Minor/Other Modes-->
          <b-row
              align-h="center">
            <b-col
                align="center"
                cols="*">
              <transition-group
                  mode="out-in"
                  name="slide-fade">
                <b-btn
                    v-for="choice in modes"
                    :key="choice"
                    :id="`button${choice}`"
                    @click="mode = choice"
                    :variant="mode === choice ? 'primary' : 'tertiary'"
                    class="chords__button__modes">
                  {{choice}}
                </b-btn>
                <b-btn
                    id="nameOfMode"
                    key="nameOfMode"
                    v-if="mode !== 'Major' && mode !== 'Minor' && !showAllModes"
                    @click="mode = mode"
                    variant="primary"
                    class="chords__button__modes">
                  {{mode}}
                </b-btn>
                <b-btn
                    id="showAllModes"
                    key="showHide"
                    @click="showAllModes = !showAllModes"
                    :variant="showAllModes ? 'secondary' : 'tertiary'"
                    class="chords__button__modes">
                  <span
                      v-text="!showAllModes ? 'Other' : 'Show Less'"
                  />
                </b-btn>
              </transition-group>
            </b-col>
          </b-row>
          <span
              key="signsCount">
              {{displaySigns}}
          </span>
        </div>
      </b-col>
      <b-col
          v-if="displayMode === 'Neck'"
          class="chords__synth"
          cols="*">
        <div
            class="chords__synth__label">
          <div
              class="chords__synth__label-header mb-2">
            Instrument
          </div>
          <b-form-select
              id="intrumentTypeSelext"
              v-model="instrumentChosen"
              class="chords__select">
            <b-form-select-option
                v-for="instrument in instrumentOptions"
                :key="instrument"
                :value="instrument">
              {{instrument}}
            </b-form-select-option>
          </b-form-select>
          <div
              class="chords__synth__label-subheader">
            Tuning
            <b-btn
                id="customizeTuningButton"
                @click="customizeTuning = !customizeTuning"
                :variant="customizeTuning ? 'primary' : 'tertiary'"
                class="ml-3">
              {{customizeTuning ? 'Done' : 'Edit'}}
            </b-btn>
          </div>
          <b-form-select
              id="tuningSelectOption"
              v-model="tuningChosen"
              class="chords__select">
            <b-form-select-option
                v-for="option in tuningOptions"
                :key="option._id"
                :value="option._id">
              {{option.label}}
            </b-form-select-option>
          </b-form-select>
          <!-- <b-row
              align-h="center"
              class="my-2">
            <b-col
                cols="*"
                align="center">
            </b-col>
          </b-row> -->
          <transition
              mode="out-in"
              name="fade">
            <div
                v-if="customizeTuning">
              <label
                  for="tuningName"
                  class="chords__synth__label-subheader my-2">
                Name
              </label>
              <input
                  id="tuningLabel"
                  :disabled="isPlaying"
                  v-model="thisTuning.label"
                  class="ml-auto mr-1 mt-2 mb-4"
                  type="text"
                  name="tuningLabel"
              />
              <transition-group
                  mode="out-in"
                  name="fade">
                <b-row
                    v-for="(string, index) in thisTuning.tuning"
                    :key="index"
                    align-h="center">
                  <b-col
                      :key="index"
                      cols="auto"
                      class="my-2">
                    <b-row
                        align-h="center">
                      <b-col
                          align="right">
                        <b-btn
                            :id="`button-up-${index}-${string}`"
                            variant="tertiary"
                            class="py-0"
                            @click="adjustTuning(true, index)">
                          <b-icon-arrow-up />
                        </b-btn>
                      </b-col>
                      <b-col
                          align="center">
                        <span
                            :id="`tuningChosen-${string}-${index}`"
                            v-html="noteName(thisTuning.tuning[index])"
                            class="chords__synth__label-subheader px-3"
                        />
                      </b-col>
                      <b-col
                          align="left">
                        <b-btn
                            :id="`button-${index}-${string}`"
                            variant="tertiary"
                            class="py-0"
                            @click="adjustTuning(false, index)">
                          <b-icon-arrow-down />
                        </b-btn>
                      </b-col>
                      <b-col
                          align="right">
                        <b-btn
                            :id="`deleteButton-${string}-${index}`"
                            variant="danger"
                            class="py-0"
                            @click="removeString(index)">
                          <b-icon-trash />
                        </b-btn>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </transition-group>
              <div
                  class="chords__synth__label-subheader">
                Add String
              </div>
              <b-row
                  id="addString">
                <b-col>
                  <b-btn
                      id="addToTop"
                      @click="newStringToTop = true"
                      :variant="newStringToTop ? 'primary' : 'tertiary'">
                    To Top
                  </b-btn>
                  <b-btn
                      id="addToBottom"
                      @click="newStringToTop = false"
                      :variant="newStringToTop ? 'tertiary' : 'primary'">
                    To Bottom
                  </b-btn>
                  <b-btn
                      id="addStringButton"
                      @click="addString()"
                      variant="primary">
                    <b-icon-plus />
                  </b-btn>
                </b-col>
              </b-row>
            </div>
          </transition>
        </div>
      </b-col>
      <b-col
          cols="*"
          align="center"
          class="chords__synth">
        <div
            class="chords__synth__label">
          <div
              class="chords__synth__label-header">
            Volume
          </div>
          <control-knob
              v-model="synthVolume"
              :disabled="isPlaying"
              :options="[0, 1, 2, 3, 4, 5, 6, 7, 11]"
              :size="'sm'"
              class="chords__synth__knob">
          </control-knob>
          <b-form-select
              id="soundType"
              name="soundSelect"
              v-model="oscillatorType"
              class="chords__select"
              :disabled="isPlaying">
            <b-form-select-option
                value="sine">
              Sine
            </b-form-select-option>
            <b-form-select-option
                value="square">
              Square
            </b-form-select-option>
            <b-form-select-option
                value="sawtooth">
              Sawtooth
            </b-form-select-option>
            <b-form-select-option
                value="triangle">
              Triangle
            </b-form-select-option>
          </b-form-select>
        </div>
      </b-col>
      <b-col
          cols="auto"
          align="center"
          class="chords__synth">
        <div
            class="chords__synth__label">
          <div
              class="chords__synth__label-header"
              style="cursor: pointer">
            <span
                v-text="synthNotes.length || showChordDuration ? 'Sound Duration' : 'Speed'"
            />
          </div>
          <!-- @click="showChordDuration = !showChordDuration" -->
          <transition
              mode="out-in"
              name="fadeQuick">
            <control-knob
                id="arpDurationKnob"
                v-if="!showChordDuration"
                v-model="arpDuration"
                :disabled="isPlaying"
                :options="arpDurationOptions"
                :size="'sm'"
                class="chords__synth__knob mx-auto"
            />
            <!-- <control-knob
                id="chordDuration"
                v-else
                v-model="chordDuration"
                :disabled="isPlaying"
                :options="arpDurationOptions"
                :size="'sm'"
                class="chords__synth__knob"
            /> -->
          </transition>
          <div
              id="repeatOptions"
              class="chords__synth__label-subheader">
            <b-btn
                id="fewerRepeats"
                :disabled="repeats === 1"
                @click="repeats -= 1"
                size="sm"
                class="py-0 px-1 ml-0 mr-2"
                variant="tertiary">
              <b-icon-arrow-down-circle-fill
                  :scale="0.9"
              />
            </b-btn>
            Plays <b v-text="repeats" /> <span v-text="repeats > 1 ? 'times' : 'time'" />
            <b-btn
                id="moreRepeats"
                :disabled="repeats === 100"
                @click="repeats += 1"
                class="py-0 px-1 mr-0 ml-2"
                variant="tertiary">
              <b-icon-arrow-up-circle-fill
                  :scale="0.9"
              />
            </b-btn>
          </div>
        </div>
        <!-- <span
          class="chords__synth__label__faded"
          @click="showChordDuration = !showChordDuration"
          style="cursor: pointer"
          v-text="!showChordDuration ? 'Sound Duration' : 'Arp. Speed'"
        /> -->
      </b-col>
      <b-col
          cols="*"
          align="center"
          class="chords__synth">
        <div
            class="chords__synth__label">
          <div
              class="chords__synth__label-header">
            Starting Octave
          </div>
          <control-knob
              v-model="startingOctave"
              :disabled="isPlaying"
              :options="octaveOptions"
              :size="'sm'"
              class="chords__synth__knob">
          </control-knob>
          <div
              id="octaveSpanOptions"
              class="chords__synth__label-subheader">
            <b-btn
                id="fewerOctaves"
                :disabled="spanOctaves === 1"
                @click="spanOctaves -= 1"
                size="sm"
                class="py-0 px-1 ml-0 mr-2"
                variant="tertiary">
              <b-icon-arrow-down-circle-fill
                  :scale="0.9"
              />
            </b-btn>
            Goes up <b v-text="spanOctaves" />
            <b-btn
                id="moreRepeats"
                :disabled="spanOctavesLimit"
                @click="spanOctaves += 1"
                size="sm"
                class="py-0 px-1 mr-0 ml-2"
                variant="tertiary">
              <b-icon-arrow-up-circle-fill
                  :scale="0.9"
              />
            </b-btn>
          </div>
        </div>
      </b-col>
      <b-col
          cols="auto"
          align="center"
          class="chords__synth my-auto">
        <div
            id="noteOptionsContainer"
            class="chords__synth__label">
          <div
              class="chords__synth__label-header">
            Options
          </div>
          <b-form-checkbox
              id="chromaticMode"
              switch
              size="lg"
              v-model="chromatic"
              class="chords__synth__label-subheader">
            Chromatic Mode
          </b-form-checkbox>
          <div
              class="chords__synth__label-subheader">
            Click In
            <b-btn-group
                id="clickInSelection"
                v-model="clickIn"
                :options="clickInOptions"
                class="ml-2">
              <b-btn
                  v-for="option in clickInOptions"
                  :key="option.value"
                  @click="clickIn = option.value"
                  :variant="clickIn === option.value ? 'primary' : 'tertiary'"
                  class="py-0"
                  v-text="option.text"
              />
            </b-btn-group>
          </div>
          <div
              class="chords__synth__label-subheader">
            <b-form-checkbox
                id="includeDescNotes"
                switch
                size="lg"
                v-model="descend">
              Include descending notes
            </b-form-checkbox>
            <transition
                name="fade"
                mode="out-in">
              <div
                  v-if="descend">
                <b-form-checkbox
                    id="repeatTonic"
                    switch
                    size="lg"
                    v-model="repeatTonic"
                    class="my-2 mx-2">
                  Repeat Tonic at Top/Bottom
                </b-form-checkbox>
              </div>
            </transition>
          </div>
        </div>
      </b-col>
      <b-col
          cols="auto"
          align="center"
          class="chords__synth">
        <div
            id="noteNameOptionsContainer"
            class="chords__synth__label">
          <div
              class="chords__synth__label-header">
            Labels
          </div>
          <b-form-checkbox
                id="hideNoteNames"
                switch
                size="lg"
                v-model="hideNoteNames"
                class="chords__synth__label-subheader">
              Hide Note Names
          </b-form-checkbox>
          <transition
              mode="out-in"
              name="fade">
            <b-form-checkbox
                  v-if="!hideNoteNames"
                  id="showOffKeyNoteNames"
                  switch
                  size="lg"
                  v-model="showAllNoteNames"
                  class="chords__synth__label-subheader">
                Show Off-Key Note Names
            </b-form-checkbox>
          </transition>
          <transition
              mode="out-in"
              name="fade">
            <b-form-checkbox
                  v-if="!hideNoteNames"
                  id="showOnlyActiveNote"
                  switch
                  size="lg"
                  v-model="showAllNoteNamesWhenPlaying"
                  class="chords__synth__label-subheader">
                Show All Note Names When Playing
            </b-form-checkbox>
          </transition>
        </div>
      </b-col>
    </b-row>
    <b-row
        align-h="center">
      <b-col
          cols="auto"
          class="px-3 mt-4 chords__synth__label"
          align="center">
        <div
            class="chords__synth__label-header my-auto px-5">
          Need help?
          <b-btn
              id="support"
              @click="showHelp = !showHelp"
              class="my-2"
              v-text="showHelp ? 'Hide Info' : 'Information'"
              variant="tertiary"
          />
        </div>
        <div
            for="referenceTone"
            class="chords__synth__label-subheader mx-5"
            v-if="showHelp">
            Reference Tone:
          {{referenceTone}}hz
        </div>
        <input
            id="referenceToneSlider"
            :disabled="isPlaying"
            v-model="referenceTone"
            class="ml-auto mr-1 my-auto"
            v-if="showHelp"
            type="range"
            name="referenceTone"
            size="sm"
            min="200"
            max="600"
        />
        <transition
            name="fade"
            mode="out-in">
          <div
              v-if="showHelp"
              class="mt-3">
            <div
                class="chords__synth__label-subheader">
              About this Project
            </div>
            <div
                class="chords__help-topic__text">
              This is software is free for personal use.
              <br />
              <small>
                Please contact the developer for commercial or educational licensing.
              </small>
              <p />
              easyintheory.com was developed by <a href="mailto:david@windsong.io">David Verdugo</a>, a lifelong musician and technology enthusiast.
              <br />
              Finding other online musical theory tools to be a bit disappointing,
              he built this site to help with musical studies and as a practice tool. Hopefully you find it useful!
              <p />
              <div
                  class="mt-3 chords__synth__label-subheader">
                If you really like this app, consider supporting the developer
              </div>
              <p />
              <!-- Paypal donation button -->
              <form
                  action="https://www.paypal.com/donate"
                  method="post"
                  target="_top">
              <input
                  type="hidden"
                  name="hosted_button_id"
                  value="SH9Z9V29JNCTU" />
              <b-btn
                  type="submit"
                  target="_blank"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                  border="0"
                  variant="primary"
                  v-text="'Donate'"
              />
              <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1" />
                <p />
              Money will be spent on coffee, guitar strings, and instruments I don't need.
              </form>

            </div>
            <div
                class="chords__synth__label-subheader mt-4 mb-2">
              Help Topics
            </div>
            <b-btn-group
                id="helpTopics"
                class="mt-2 mb-3">
              <b-btn
                  id="scaleHelp"
                  :variant="showHelpTopic === 'scale' ? 'primary' : 'tertiary'"
                  @click="clickHelpTopic('scale')">
                Scale Selection
              </b-btn>
              <b-btn
                  id="arpHelp"
                  :variant="showHelpTopic === 'arp' ? 'primary' : 'tertiary'"
                  @click="clickHelpTopic('arp')">
                Arpeggiator
              </b-btn>
              <b-btn
                  id="somethingWrong"
                  :variant="showHelpTopic === 'somethingWrong' ? 'primary' : 'tertiary'"
                  @click="clickHelpTopic('somethingWrong')">
                Something Wrong?
              </b-btn>
              <b-btn
                  id="termsOfUse"
                  :variant="showHelpTopic === 'termsOfUse' ? 'primary' : 'tertiary'"
                  @click="clickHelpTopic('termsOfUse')">
                Terms of Use
              </b-btn>
            </b-btn-group>
            <!--
            <h3
                @click="showHelpTopic.chords = !showHelpTopic.chords"
                class="chords__help-topic">
              Chords
            </h3>
            <transition
                mode="out-in"
                name="slide-fade">
              <span
                  v-if="showHelpTopic.chords"
                  class="chords__help-topic__text">
                By clicking or tapping on a letter in the scale, you enter Chord Mode.
                <br />Pressing play while one or more notes are active, the system will play a chord using those notes.
                <br />The length of the chord is determined using the "<b>Chord Duration</b>" knob (only available in Chord Mode).
                The software will stay in chord mode until there are no highlighted notes.
              </span>
            </transition>
            -->
            <transition
                mode="out-in"
                name="slide-fade">
              <div
                  v-if="showHelpTopic === 'scale'">
                Select a key and scale.
                You can pick any letter, make it flat or sharp, and then choose from different modes (major, minor, other).
                <br />
                <b>
                  Please note that this software does not distinguish between traditional and theoretical keys.
                  So, if you want to see and hear the notes for B# (instead of C), go ahead!
                </b>
              </div>
            </transition>
            <transition
                mode="out-in"
                name="slide-fade">
              <div
                  v-if="showHelpTopic === 'arp'">
                After you select a key, pressing the play button will play each of the notes in that key, in succession.
                You can change how quickly the notes play using the "<b>Speed</b>" knob.
                You can also change how many octaves are included, as well as adding a descending scale.
              </div>
            </transition>
            <transition
                mode="out-in"
                name="slide-fade">
              <div
                  v-if="showHelpTopic === 'somethingWrong'">
                Refreshing the page or pressing the RESTART button will resolve most issues.
                <br />If you would like to erase all saved preferences and custom tunings, press the RESET button.
                <p />
                <b-btn
                    size="sm"
                    variant="info"
                    @click="softwareReset()"
                    class="mx-4 px-4">
                  Restart
                </b-btn>
                <b-btn
                    size="sm"
                    variant="warning"
                    @click="softwareReset(true)"
                    class="mx-4 px-4">
                  Reset
                </b-btn>
                <p />If you are continuing to experience an issue, please email the developer:
                <br /><a href="mailto:david@windsong.io">David Verdugo</a>
              </div>
            </transition>
            <transition
                mode="out-in"
                name="slide-fade">
              <div
                  v-if="showHelpTopic === 'termsOfUse'">
                By visiting this site and/or using this software, you agree to the following:
                <p />
                - This software shall be used for personal use only.
                <a href="mailto:david@windsong.io">Contact the developer</a>
                if you are interested in using the software for a commercial purpose or in a formal eductational setting.
                <p />
                - You shall hold the developer harmless for any damages caused by visiting this site or using the software
                <b>(such as, if your speakers are damaged by turning the volume up to 11)</b>
              </div>
            </transition>
          </div>
        </transition>
      </b-col>
    </b-row>
    <b-row
        align-h="center">
      <b-col
          cols="center">
        <small>
          Copyright David Verdugo - Built 2021
        </small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import totalCalculators from '@/mixins/totalCalculators';
import controlKnob from '@/components/chordWizard/Knob';

const AudioContext = window.AudioContext || window.webkitAudioContext || false;

export default {
  title() {
    return 'easyintheory.com - Musical Tools You Can Use';
  },
  mounted() {
    if (!this.stringsData.length) {
      this.instrumentChosen = this.instrumentOptions[0];
      this.tuningChosen = this.tuningOptions[0]._id;
      this.updateNeck();
    }
    this.recalculateNotes();
    this.synth = new AudioContext();
    // Setting timeout to delay watchers from updating again and again for every vuex option as it loads
    setTimeout(() => {
      this.readyToRun = true;
      if (!AudioContext) {
        // eslint-disable-next-line no-alert
        alert('Sorry, this app will not play sounds on your system.');
      }
    }, 100);
  },
  components: {
    controlKnob,
  },
  // mixins: [
  //   totalCalculators,
  // ],
  computed: {
    arpDuration: {
      get() {
        return this.$store.state.chords.arpDuration;
      },
      set(newVal) {
        this.$store.commit('arpDuration', newVal);
      },
    },
    chordDuration: {
      get() {
        return this.$store.state.chords.chordDuration;
      },
      set(newVal) {
        this.$store.commit('chordDuration', newVal);
      },
    },
    chromatic: {
      get() {
        return this.$store.state.chords.chromatic;
      },
      set(newVal) {
        this.$store.commit('chromatic', newVal);
      },
    },
    chromaticNotes() {
      const notes = {};
      for (let note = 0; note < this.notes.length; note += 1) {
        for (let octave = 0; octave < 9; octave += 1) {
          const thisNote = {
            hz: this.stepFromRef(this.notes[note].steps, octave),
            names: this.notes[note].names,
            name: null,
            octave,
          };
          const index = thisNote.hz.toFixed(5);
          if (!notes[index]) {
            notes[index] = thisNote;
          }
        }
      }
      return notes;
    },
    clickIn: {
      get() {
        return this.$store.state.chords.clickIn;
      },
      set(newVal) {
        this.$store.commit('clickIn', newVal);
      },
    },
    descend: {
      get() {
        return this.$store.state.chords.descend;
      },
      set(newVal) {
        this.$store.commit('descend', newVal);
      },
    },
    displayRelativeMajor() {
      let relativeMajor = this.relativeMajor;
      if (relativeMajor.length > 1) {
        if (relativeMajor.substr(1, 5) === 'Sharp') {
          relativeMajor = relativeMajor[0];
          relativeMajor += '♯';
        } else {
          relativeMajor = relativeMajor[0];
          relativeMajor += '♭';
        }
      }
      relativeMajor += ' Major';
      return relativeMajor;
    },
    displayMode: {
      get() {
        return this.$store.state.chords.displayMode;
      },
      set(newVal) {
        this.$store.commit('displayMode', newVal);
      },
    },
    displaySigns() {
      const signs = {
        sharps: 0,
        flats: 0,
        doubleSharps: 0,
        doubleFlats: 0,
      };
      Object.entries(this.key).forEach((note) => {
        if (note[1].quality !== 8) {
          switch (note[1].name.slice(1)) {
            case ('♯'): signs.sharps += 1; break;
            case ('♭'): signs.flats += 1; break;
            case ('𝄪'): signs.doubleSharps += 1; break;
            case ('𝄫'): signs.doubleFlats += 1; break;
            default: break;
          }
        }
      });
      let signCount;
      if (signs.sharps === 1) signCount = `${signs.sharps} Sharp`;
      if (signs.sharps > 1) signCount = `${signs.sharps} Sharps`;
      if (signs.doubleSharps) signCount += ` and ${signs.doubleSharps} Double Sharps`;
      if (signs.flats > 1) signCount = `${signs.flats} Flats`;
      if (signs.flats === 1) signCount = `${signs.flats} Flat`;
      if (signs.doubleFlats) signCount += ` and ${signs.doubleFlats} Double Flats`;
      return signCount;
    },
    hideNoteNames: {
      get() {
        return this.$store.state.chords.hideNoteNames;
      },
      set(newVal) {
        this.$store.commit('hideNoteNames', newVal);
      },
    },
    instrumentChosen: {
      get() {
        return this.$store.state.chords.instrumentChosen;
      },
      set(newVal) {
        this.$store.commit('instrumentChosen', newVal);
      },
    },
    leftHand: {
      get() {
        return this.$store.state.chords.leftHand;
      },
      set(newVal) {
        this.$store.commit('leftHand', newVal);
      },
    },
    keyOf: {
      get() {
        return this.$store.state.chords.keyOf;
      },
      set(newVal) {
        this.$store.commit('keyOf', newVal);
      },
    },
    mode: {
      get() {
        return this.$store.state.chords.mode;
      },
      set(newVal) {
        this.$store.commit('mode', newVal);
      },
    },
    oscillatorType: {
      get() {
        return this.$store.state.chords.oscillatorType;
      },
      set(newVal) {
        this.$store.commit('oscillatorType', newVal);
      },
    },
    referenceTone: {
      get() {
        return this.$store.state.chords.referenceTone;
      },
      set(newVal) {
        this.$store.commit('chromatic', newVal);
      },
    },
    repeats: {
      get() {
        return this.$store.state.chords.repeats;
      },
      set(newVal) {
        this.$store.commit('repeats', newVal);
      },
    },
    repeatTonic: {
      get() {
        return this.$store.state.chords.repeatTonic;
      },
      set(newVal) {
        this.$store.commit('repeatTonic', newVal);
      },
    },
    showAllNoteNames: {
      get() {
        return this.$store.state.chords.showAllNoteNames;
      },
      set(newVal) {
        this.$store.commit('showAllNoteNames', newVal);
      },
    },
    showAllNoteNamesWhenPlaying: {
      get() {
        return this.$store.state.chords.showAllNoteNamesWhenPlaying;
      },
      set(newVal) {
        this.$store.commit('showAllNoteNamesWhenPlaying', newVal);
      },
    },
    spanOctaves: {
      get() {
        return this.$store.state.chords.spanOctaves;
      },
      set(newVal) {
        this.$store.commit('spanOctaves', newVal);
      },
    },
    startingOctave: {
      get() {
        return this.$store.state.chords.startingOctave;
      },
      set(newVal) {
        this.$store.commit('startingOctave', newVal);
      },
    },
    stringsData: {
      get() {
        return this.$store.getters.stringsData;
      },
      set(newVal) {
        this.$store.commit('stringsData', newVal);
      },
    },
    synthVolume: {
      get() {
        return this.$store.state.chords.synthVolume;
      },
      set(newVal) {
        this.$store.commit('synthVolume', newVal);
      },
    },
    tuningChosen: {
      get() {
        return this.$store.getters.tuningChosen;
      },
      set(newVal) {
        this.$store.commit('tuningChosen', newVal);
      },
    },
    modes() {
      if (!this.showAllModes) {
        return [...this.modesOptions.slice(0, 2)];
      }
      return this.modesOptions;
    },
    letter: {
      get() {
        return this.$store.state.chords.letter;
      },
      set(newVal) {
        this.$store.commit('letter', newVal);
      },
    },
    sharp: {
      get() {
        return this.$store.state.chords.sharp;
      },
      set(newVal) {
        this.$store.commit('sharp', newVal);
      },
    },
    flat: {
      get() {
        return this.$store.state.chords.flat;
      },
      set(newVal) {
        this.$store.commit('flat', newVal);
      },
    },
    nameOfKey() {
      let key = this.letter;
      if (this.sharp) {
        key += '♯';
      }
      if (this.flat) {
        key += '♭';
      }
      key += ' ';
      key += this.mode;
      return key;
    },
    neckNotesInHz() {
      // Just for reference. toFixed(5)
      const notes = [];
      const notesOnNeck = [];
      if (this.stringsData) {
        this.stringsData.forEach((string) => {
          string.forEach((fret) => {
            if (notes.indexOf(this.stepFromRef(fret.steps)) === -1) {
              notes.push(this.stepFromRef(fret.steps));
            }
          });
        });
        notes.sort((a, b) => ((a > b) ? 1 : -1)).forEach(n => notesOnNeck.push(n.toFixed(5)));
      }
      return notesOnNeck;
    },
    referenceTones() {
      // Return ref tone per octave
      return {
        0: this.referenceTone / 2 / 2 / 2 / 2,
        1: this.referenceTone / 2 / 2 / 2,
        2: this.referenceTone / 2 / 2,
        3: this.referenceTone / 2,
        4: this.referenceTone,
        5: this.referenceTone * 2,
        6: this.referenceTone * 2 * 2,
        7: this.referenceTone * 2 * 2 * 2,
        8: this.referenceTone * 2 * 2 * 2 * 2,
      };
    },
    octaveOptions() {
      if (this.displayMode === 'Neck') {
        const octavesOnNeck = [];
        for (let a = 0; a < (this.tonicsOnNeck.length - 1); a += 1) {
          // length - 1 to exclude top option
          octavesOnNeck.push(this.noteInfo(this.tonicsOnNeck[a]).octave);
        }
        return octavesOnNeck;
      }
      return [0, 1, 2, 3, 4, 5, 6, 7, 8];
    },
    spanOctavesLimit() {
      if (this.displayMode === 'Neck' && this.tonicsOnNeck.length) {
        if ((this.tonicsOnNeck[this.tonicsOnNeck.length - 1][1].octave - this.startingOctave) > this.spanOctaves) {
          return false;
        }
        return true;
      }
      if (8 - this.startingOctave > this.spanOctaves) {
        return false;
      }
      return true;
    },
    tonicNotes() {
      const notes = Object.entries(this.notesInKey).filter(i => i[1].quality === 1);
      return notes;
    },
    tonicsOnNeck() {
      return this.tonicNotes.filter(i => this.neckNotesInHz.indexOf(i[0]) > -1);
    },
    thisTuning: {
      get() {
        return this.$store.getters.thisTuning;
      },
      set(newVal) {
        this.$store.commit('thisTuning', newVal);
      },
    },
    tuningOptions: {
      get() {
        return this.$store.getters.tuningOptions;
      },
      set(newVal) {
        this.$store.commit('tuningOptions', newVal);
      },
    },
  },
  data() {
    return {
      arpDurationOptions: [
        {
          value: 0.25,
          label: 'Lightning',
        },
        {
          value: 0.5,
          label: 'Quick',
        },
        {
          value: 1,
          label: 'Steady',
        },
        {
          value: 2,
          label: 'Gradual',
        },
      ],
      clickInOptions: [
        { value: 0, text: 'Off' },
        { value: 1, text: 'Short' },
        { value: 2, text: 'Long' },
      ],
      customizeTuning: false,
      displayOptions: [
        'Neck',
        'Notes',
      ],
      displayFrets: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
      ],
      instrumentOptions: [
        'Guitar',
        'Bass',
        'Violin',
        'Ukulele',
        'Mandolin',
        'Viola',
        'Cello',
        'Custom',
      ],
      newStringToTop: false,
      gainNodes: {},
      hovering: false,
      hoveringOver: null,
      isPlaying: false,
      key: {},
      notesInKey: {},
      letters: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
      ],
      modesOptions: [
        'Major',
        'Minor',
        'Dorian',
        'Phyrgian',
        'Lydian',
        'Mixolydian',
        'Locrian',
      ],
      notePlaying: null,
      notes: [
        {
          names: [
            'A',
            'G𝄪',
            'B𝄫',
          ],
          steps: 0, // Steps from ref
        },
        {
          names: [
            'A♯',
            'B♭',
            'C𝄫',
          ],
          steps: 1,
        },
        {
          names: [
            'B',
            'A𝄪',
            'C♭',
          ],
          steps: 2,
        },
        {
          names: [
            'C',
            'B♯',
            'D𝄫',
          ],
          steps: -9,
        },
        {
          names: [
            'C♯',
            'D♭',
            'B𝄪',
          ],
          steps: -8,
        },
        {
          names: [
            'D',
            'C𝄪',
            'E𝄫',
          ],
          steps: -7,
        },
        {
          names: [
            'D♯',
            'E♭',
            'F𝄫',
          ],
          steps: -6,
        },
        {
          names: [
            'E',
            'F♭',
            'D𝄪',
          ],
          steps: -5,
        },
        {
          names: [
            'F',
            'E♯',
            'G𝄫',
          ],
          steps: -4,
        },
        {
          names: [
            'F♯',
            'G♭',
            'E𝄪',
          ],
          steps: -3,
        },
        {
          names: [
            'G',
            'F𝄪',
            'A𝄫',
          ],
          steps: -2,
        },
        {
          names: [
            'G♯',
            'A♭',
          ],
          steps: -1,
        },
      ],
      oscillators: {},
      playingNotes: {},
      readyToRun: false,
      relativeMajor: 'C',
      showAllModes: false,
      showChordDuration: false,
      showHelp: false,
      showHelpTopic: null,
      showSupport: false,
      synth: null, // window.audiocontext
      synthId: null,
      synths: {},
      synthNotes: [],
      timeouts: [],
    };
  },
  methods: {
    addNoteToSynth(noteQuality) {
      const index = this.synthNotes.indexOf(noteQuality);
      if (index > -1) {
        this.synthNotes.splice(index, 1);
      } else {
        this.synthNotes.push(noteQuality);
      }
      if (this.synthNotes.length) {
        this.showChordDuration = true;
      } else {
        this.showChordDuration = false;
      }
    },
    addString() {
      if (!this.thisTuning.tuning.length) {
        // If no strings currently exist, add one
        this.thisTuning.tuning.push(0);
      } else {
        let predictedInterval;
        const tuning = this.thisTuning.tuning;
        if (this.newStringToTop) {
          predictedInterval = tuning[0] + 5;
          if (!this.noteName(predictedInterval)) {
            for (let a = 0; a < 109; a += 1) {
              predictedInterval -= 1;
              if (this.noteName(predictedInterval)) {
                break;
              }
            }
          }
          this.thisTuning.tuning.splice(0, 0, predictedInterval);
        } else {
          predictedInterval = tuning[tuning.length - 1] - 5;
          if (!this.noteName(predictedInterval)) {
            for (let a = 0; a < 109; a += 1) {
              predictedInterval += 1;
              if (this.noteName(predictedInterval)) {
                break;
              }
            }
          }
          this.thisTuning.tuning.push(predictedInterval);
        }
      }
    },
    adjustTuning(up, string) {
      this.$store.commit('adjustTuning', { up, string });
      this.recalculateNotes();
    },
    buttonSharp() {
      this.flat = false;
      this.sharp = !this.sharp;
    },
    buttonFlat() {
      this.sharp = false;
      this.flat = !this.flat;
    },
    classBindFret(string, index) {
      if (this.thisTuning) {
        const classes = [];
        if ((index === 0 && !this.leftHand)
        || (this.leftHand && index === string.length - 1)) {
          classes.push('neck__fret__open');
        }
        if (this.thisTuning.fretless) {
          classes.push('neck__fret__fretless');
        }
        if ((index === 0 && !this.leftHand)
        || (index === string.length - 1 && this.leftHand)
        || (index === string.length - 2 && this.leftHand)) {
          classes.push('neck__fret__hide-fret');
        }
        if (this.leftHand) {
          classes.push('neck__fret__southpaw');
          if (index === 0) {
            classes.push('neck__fret__show-left');
          }
        }
        return classes;
      }
      return null;
    },
    clickHelpTopic(topic) {
      if (this.showHelpTopic !== topic) {
        this.showHelpTopic = topic;
      } else {
        this.showHelpTopic = null;
      }
    },
    noteName(interval, omitOctave) {
      const hz = this.stepFromRef(interval, 4);
      if (this.chromaticNotes[hz.toFixed(5)]) {
        let name;
        const octave = this.chromaticNotes[hz.toFixed(5)].octave;
        if (this.chromaticNotes[hz.toFixed(5)].names[0].length === 1) {
          // Returns A, B, C
          name = this.chromaticNotes[hz.toFixed(5)].names[0];
        } else {
          // Returns Ab/G# D#/Eb etc
          name = this.chromaticNotes[hz.toFixed(5)].names.slice(0, 2).join('/');
        }
        if (omitOctave) {
          return name;
        }
        return `${name}<small>${octave}</small>`;
      }
      return null;
    },
    noteInfo(hz) {
      return this.chromaticNotes[hz[0]];
    },
    noteDisplayText(fret) {
      // Displays the name on the neck/fretboard
      if (!this.hideNoteNames) {
        if ((this.showAllNoteNamesWhenPlaying || !this.isPlaying) || this.notePlaying === fret.index) {
          if (this.notesInKey[fret.index] && !this.notesInKey[fret.index].notInKey) {
            return this.notesInKey[fret.index].name;
          }
          if (this.showAllNoteNames) {
            return `<small>${this.noteName(fret.steps, true)}</small>`;
          }
        }
      }
      return null;
    },
    playButton() {
      if (!this.isPlaying) {
        if (this.synthNotes.length) {
          this.playChord();
        } else {
          this.playArp();
        }
      } else {
        this.stopButton();
      }
    },
    playArp() {
      this.isPlaying = true;
      let allFreqs = [];
      if (this.displayMode !== 'Neck') {
        // TODO -- this whole thing needs to be reworked, as rest of func was changed to work with stringMode
        const freqs = [];
        const notes = Object.entries(this.key);
        for (let octave = 0; octave < this.spanOctaves; octave += 1) {
          for (let noteIndex = 0; noteIndex < notes.length; noteIndex += 1) {
            let skipPush = false;
            let hz = notes[noteIndex][1].hz;
            if (octave > 0) {
              hz = (hz * 2) * octave;
            }
            if (octave > 0 && noteIndex === 0) { // ie don't repeat tonic mid run
              skipPush = true;
            }
            if (!this.repeatTonic) {
              if (this.descend && noteIndex === notes.length) {
                skipPush = true;
              }
            }
            if (!skipPush) {
              freqs.push(hz);
            }
          }
        }
        if (this.descend) {
          const count = freqs.length - 1;
          for (let a = count; a >= 0; a -= 1) {
            let skipPush = false;
            const hz = freqs[a];
            if (this.descend && !this.repeatTonic && a === freqs.length - 1) { // ie don't repeat tonic mid run
              skipPush = true;
            }
            if (!skipPush) {
              freqs.push(hz);
            }
          }
        }
        for (let repeat = 0; repeat < this.repeats; repeat += 1) {
          // Repeats needs to occur as separate loop.
          // Repeat > octaves > notes messes up the math when this.descend is enabled.
          for (let a = 0; a < freqs.length; a += 1) {
            if (repeat > 0) {
              if (!(!this.repeatTonic && a === 0)) {
                allFreqs.push(freqs[a]);
              }
            } else {
              allFreqs.push(freqs[a]);
            }
          }
        }
      } else {
        const freqs = [];
        const tonicMap = [...this.tonicsOnNeck];
        let bottomTonic;
        let topTonic;
        for (let a = 0; a < tonicMap.length; a += 1) {
          if (this.notesInKey[this.tonicsOnNeck[a][0]]
            && this.notesInKey[this.tonicsOnNeck[a][0]].octave === this.startingOctave) {
            bottomTonic = this.notesInKey[this.tonicsOnNeck[a][0]].hz.toFixed(5);
          }
          if (this.notesInKey[this.tonicsOnNeck[a][0]]
            && this.notesInKey[this.tonicsOnNeck[a][0]].octave === (this.startingOctave + this.spanOctaves)) {
            topTonic = this.notesInKey[this.tonicsOnNeck[a][0]].hz.toFixed(5);
          }
        }
        const startingIndex = this.neckNotesInHz.indexOf(bottomTonic); // First instance of tonic
        const endingIndex = this.neckNotesInHz.indexOf(topTonic); // Last instance of tonic, one above last note
        for (let a = startingIndex; a <= endingIndex; a += 1) {
          if (this.notesInKey[this.neckNotesInHz[a]]) {
            freqs.push(this.neckNotesInHz[a]);
          }
        }
        if (this.descend) {
          const count = freqs.length - 1;
          for (let a = count; a >= 0; a -= 1) {
            // if !repeatTonic, don't repeat tonic mid run
            if (!(this.descend
                  && !this.repeatTonic
                  && a === freqs.length - 1)) {
              freqs.push(freqs[a]);
            }
          }
        }
        const withRepeats = [];
        for (let repeat = 0; repeat < this.repeats; repeat += 1) {
          // Repeats needs to occur as separate loop.
          for (let note = 0; note < freqs.length; note += 1) {
            // Don't repeat bottom
            if (!(!this.repeatTonic
                && note === freqs.length - 1
                && repeat !== this.repeats - 1)) {
              withRepeats.push(freqs[note]);
            }
          }
        }
        allFreqs = withRepeats;
      }
      let playCount = 0;

      const synth = this.synth;
      const synthId = this.$guid();
      this.synthId = synthId;
      if (this.clickIn) {
        // play click in sound, modeled after hihat
        let clicks;
        switch (this.clickIn) {
          default: clicks = 0; break;
          case (1): clicks = 4; break;
          case (2): clicks = 8; break;
        }
        for (let a = 0; a < clicks; a += 1) {
          this.playHiHat(playCount);
          playCount += this.arpDuration.value;
        }
      }
      for (let a = 0; a < allFreqs.length; a += 1) {
        const timeout = setTimeout(() => {
          this.notePlaying = allFreqs[a];
        }, playCount * 1000);
        this.timeouts.push(timeout);
        const oscillator = synth.createOscillator();
        const gainNode = synth.createGain();
        const hz = allFreqs[a];
        gainNode.gain.value = this.volumeCalc(hz);
        oscillator.connect(gainNode);
        gainNode.connect(synth.destination);
        oscillator.type = this.oscillatorType;
        oscillator.frequency.value = hz;
        oscillator.start(synth.currentTime + playCount);
        playCount += this.arpDuration.value;
        gainNode.gain.setValueAtTime(this.volumeCalc(hz), synth.currentTime + playCount - 0.05);
        gainNode.gain.linearRampToValueAtTime(0.0001, synth.currentTime + playCount - 0.01);
        oscillator.stop(synth.currentTime + playCount);
        if (a === allFreqs.length - 1) {
          setTimeout(() => {
            this.notePlaying = null;
          }, playCount * 1000);
        }
      }
      setTimeout(() => {
        if (this.synthId === synthId) {
          this.stopButton();
        }
      }, playCount * 1000);
    },
    playChord() {
      this.isPlaying = true;
      const freqs = [];
      this.synthNotes.forEach((noteQuality) => {
        freqs.push(this.key[noteQuality - 1].hz);
      });
      for (let octave = 0; octave < this.spanOctaves; octave += 1) {
        for (let a = 0; a < this.synthNotes.length; a += 1) {
          if (octave === 0) {
            freqs.push(this.key[this.synthNotes[a] - 1].hz);
          } else {
            freqs.push((this.key[this.synthNotes[a] - 1].hz * 2) * octave);
          }
        }
      }
      const synthId = this.$guid();
      this.synthId = synthId;
      for (let a = 0; a < freqs.length; a += 1) {
        const synth = this.synth;
        const oscillator = synth.createOscillator();
        const gainNode = synth.createGain();
        const hz = this.setOctave(freqs[a]);
        gainNode.gain.value = this.volumeCalc(hz);
        oscillator.connect(gainNode);
        gainNode.connect(synth.destination);
        oscillator.type = this.oscillatorType;
        oscillator.frequency.value = hz;
        oscillator.start(synth.currentTime);
        gainNode.gain.setValueAtTime(this.volumeCalc(hz), synth.currentTime + (this.chordDuration.value - 0.1));
        gainNode.gain.linearRampToValueAtTime(0.0001, synth.currentTime + this.chordDuration.value);
        oscillator.stop(synth.currentTime + this.chordDuration.value + 0.1);
        this.playing = false;
      }
      setTimeout(() => {
        if (synthId === this.synthId) {
          this.stopButton();
        }
      }, this.chordDuration.value * 1000);
    },
    playNeckSound(note) {
      if (note) {
        this.playSound(note);
      }
    },
    playNeckSoundClick(note) {
      if (note) {
        this.playSound(note);
        setTimeout(() => {
          this.stopSound(note);
        }, 750);
      }
    },
    stopNeckSound(note) {
      if (note) {
        this.stopSound(note);
      }
    },
    playHiHat(when) {
      if (this.synthVolume !== 0) {
        const context = new AudioContext();
        const fundamental = 40;
        const ratios = [2, 3, 4.16, 5.43, 6.79, 8.21];

        const gain = context.createGain();

        // Bandpass
        const bandpass = context.createBiquadFilter();
        bandpass.type = 'bandpass';
        bandpass.frequency.value = 10000;

        // Highpass
        const highpass = context.createBiquadFilter();
        highpass.type = 'highpass';
        highpass.frequency.value = 7000;

        // Connect the graph
        bandpass.connect(highpass);
        highpass.connect(gain);
        gain.connect(context.destination);

        // Create the oscillators
        ratios.forEach((ratio) => {
          const osc = context.createOscillator();
          osc.type = 'square';
          // Frequency is the fundamental * this oscillator's ratio
          osc.frequency.value = fundamental * ratio;
          osc.connect(bandpass);
          osc.start(when);
          osc.stop(when + 0.3);
        });

        // Define the volume envelope
        const level = this.volumeCalc('hihat');
        gain.gain.setValueAtTime(0.00001, when);
        gain.gain.exponentialRampToValueAtTime(level, when + 0.02);
        gain.gain.exponentialRampToValueAtTime(level * 0.3, when + 0.03);
        gain.gain.exponentialRampToValueAtTime(0.00001, when + 0.3);
      }
    },
    playSound(note) {
      if (this.synthVolume > 0) {
        const hz = note.hz;
        const synthId = hz.toFixed(5);
        if (!this.playingNotes[synthId]) {
          this.playingNotes[synthId] = true;
          this.hoveringOver = note.quality ? note.quality : note;
          this.synths[synthId] = new AudioContext();
          this.oscillators[synthId] = this.synths[synthId].createOscillator();
          this.gainNodes[synthId] = this.synths[synthId].createGain();
          this.oscillators[synthId].connect(this.gainNodes[synthId]);
          this.gainNodes[synthId].connect(this.synths[synthId].destination);
          this.oscillators[synthId].type = this.oscillatorType;
          this.oscillators[synthId].frequency.value = hz;
          this.oscillators[synthId].start(this.synths[synthId].currentTime);
          this.gainNodes[synthId].gain.setValueAtTime(0.0000001, this.synths[synthId].currentTime);
          this.gainNodes[synthId].gain.linearRampToValueAtTime(this.volumeCalc(hz), this.synths[synthId].currentTime + 0.001);
        }
        // this.gainNodes[synthId].gain.exponentialRampToValueAtTime(, this.synths[synthId].currentTime + 0.15);
      }
    },
    recalculateNotes() {
      // Start by finding root note in this.notes
      const tones = this.notes;
      const tonesInOrder = [];
      let startingTone = this.letter;
      let startingToneIndex;
      if (this.sharp) startingTone += '♯';
      if (this.flat) startingTone += '♭';
      // Add notes to array in correct order
      for (let a = 0; a < tones.length; a += 1) {
        // Calculate pitch
        // Find root tone
        if (tones[a].names.indexOf(startingTone) > -1) {
          startingToneIndex = a;
          tonesInOrder.push({
            nameOptions: tones[a].names,
            name: null, // tbd once tones are in order
            steps: tones[a].steps,
          });
        // Once root tone is established, add all subsequent tones
        } else if (tonesInOrder.length) {
          tonesInOrder.push({
            nameOptions: tones[a].names,
            name: null,
            steps: tones[a].steps,
          });
        }
        // Add missing tones to end of array
        if (a === tones.length - 1 && tonesInOrder.length !== 12) {
          for (let b = 0; b < startingToneIndex; b += 1) {
            const tone = {
              nameOptions: tones[b].names,
              name: null,
              steps: tones[b].steps,
            };
            tonesInOrder.push(tone);
          }
        }
      }
      // Determine which tones are in the key
      let notesInMode;
      switch (this.mode) {
        default: break;
        case ('Major'): notesInMode = [0, 2, 4, 5, 7, 9, 11]; break;
        case ('Minor'): notesInMode = [0, 2, 3, 5, 7, 8, 10]; break;
        case ('Dorian'): notesInMode = [0, 2, 3, 5, 7, 9, 10]; break;
        case ('Phyrgian'): notesInMode = [0, 1, 3, 5, 7, 8, 10]; break;
        case ('Lydian'): notesInMode = [0, 2, 4, 6, 7, 9, 11]; break;
        case ('Mixolydian'): notesInMode = [0, 2, 4, 5, 7, 9, 10]; break;
        case ('Locrian'): notesInMode = [0, 1, 3, 5, 6, 8, 10]; break;
      }
      const key = {};
      for (let a = 0; a < notesInMode.length; a += 1) {
        key[a] = tonesInOrder[notesInMode[a]];
        key[a].quality = a + 1; // the quality of the note within the scale (1st, 2nd, 3rd...)
        if (a + 1 === notesInMode.length) {
          // Add 8th (octave)
          key[a + 1] = {
            nameOptions: key[0].nameOptions,
            name: null,
            quality: 8,
            steps: key[0].steps + 12,
          };
        }
      }
      // Apply note names
      let lettersInOrder;
      switch (this.letter) {
        // Determine the proper order of letters
        case ('A'): lettersInOrder = [...this.letters]; break;
        case ('B'): lettersInOrder = this.letters.slice(1); lettersInOrder.push(...this.letters.slice(0, 1)); break;
        case ('C'): lettersInOrder = this.letters.slice(2); lettersInOrder.push(...this.letters.slice(0, 2)); break;
        case ('D'): lettersInOrder = this.letters.slice(3); lettersInOrder.push(...this.letters.slice(0, 3)); break;
        case ('E'): lettersInOrder = this.letters.slice(4); lettersInOrder.push(...this.letters.slice(0, 4)); break;
        case ('F'): lettersInOrder = this.letters.slice(5); lettersInOrder.push(...this.letters.slice(0, 5)); break;
        case ('G'): lettersInOrder = this.letters.slice(6); lettersInOrder.push(...this.letters.slice(0, 6)); break;
        default: break;
      }
      // Find the correct name of each note -- ie G# vs Ab
      for (let a = 0; a < lettersInOrder.length; a += 1) {
        const thisLetter = lettersInOrder[a];
        let correctName;
        key[a]._id = this.$guid();
        key[a].nameOptions.forEach((name) => {
          if (name[0] === thisLetter) correctName = name;
        });
        key[a].name = correctName;
        if (a + 1 === lettersInOrder.length) {
          key[a + 1].name = key[0].name;
        }
      }
      // Output key info
      this.key = key;
      // Determine which frequencies are in the key
      this.updateNotesInKey();
      // Update the app
      this.updateNeck();
      if (this.spanOctavesLimit) {
        this.spanOctaves = 1;
      }
    },
    removeString(index) {
      this.thisTuning.tuning.splice(index, 1);
    },
    updateNotesInKey() {
      const notesInKey = {};
      const qualities = Object.entries(this.key);
      qualities.forEach((noteInKey) => {
        if (noteInKey[0] < 7) { // 7 is octave
          for (let octave = 0; octave < 9; octave += 1) {
            const thisNote = {
              hz: this.stepFromRef(noteInKey[1].steps, octave),
              name: noteInKey[1].name,
              quality: noteInKey[1].quality,
              octave,
            };
            const index = thisNote.hz.toFixed(5);
            notesInKey[index] = thisNote;
          }
        }
      });
      if (this.chromatic) {
        for (let note = 0; note < this.notes.length; note += 1) {
          for (let octave = 0; octave < 9; octave += 1) {
            const thisNote = {
              hz: this.stepFromRef(this.notes[note].steps, octave),
              name: null,
              quality: null,
              notInKey: true,
              octave,
            };
            const index = thisNote.hz.toFixed(5);
            if (!notesInKey[index]) {
              notesInKey[index] = thisNote;
            }
          }
        }
      }
      this.notesInKey = notesInKey;
    },
    setOctave(steps, oct) {
      let octave = oct;
      if (!octave || octave === 0) {
        octave = this.startingOctave;
      }
      return this.stepFromRef(steps, octave);
    },
    softwareReset(hardReset) {
      if (hardReset) {
        setTimeout(() => { window.localStorage.removeItem('easyintheory.com'); }, 100);
      }
      setTimeout(() => { this.$router.go(0); }, 250);
    },
    stepFromRef(steps, octave) {
      let ref = 4; // when no octave is specified, assume a440
      if (octave || octave === 0) {
        ref = octave;
      }
      return this.referenceTones[ref] * (2 ** (steps / 12));
    },
    stopButton() {
      this.isPlaying = false;
      this.timeouts.forEach(to => clearTimeout(to));
      this.notePlaying = null;
      this.synth.close();
      this.synth = new AudioContext();
    },
    stopSound(note) {
      if (this.synthVolume > 0) {
        const synthId = note.hz.toFixed(5);
        this.hoveringOver = null;
        if (!this.synths[synthId]) {
          this.synths[synthId] = new AudioContext();
        }
        if (!this.oscillators[synthId]) {
          this.oscillators[synthId] = this.synths[synthId].createOscillator();
        }
        if (!this.gainNodes[synthId]) {
          this.gainNodes[synthId] = this.synths[synthId].createGain();
        }
        this.gainNodes[synthId].gain.cancelScheduledValues(this.synths[synthId].currentTime);
        this.gainNodes[synthId].gain.setValueAtTime(this.volumeCalc(note.hz || note), this.synths[synthId].currentTime);
        this.gainNodes[synthId].gain.linearRampToValueAtTime(0.0000001, this.synths[synthId].currentTime + 0.45);
        this.gainNodes[synthId].gain.value = 0;
        this.oscillators[synthId].stop(this.synths[synthId].currentTime + 0.48);
        this.playingNotes[synthId] = false;
      }
    },
    stringWidth(string) {
      // determines visual size of string based on steps from refereceTone
      let height = 1;
      if (string <= -10) {
        height = 2;
      }
      if (string <= -14) {
        height = 3;
      }
      if (string <= -19) {
        height = 4;
      }
      if (string <= -24) {
        height = 5;
      }
      if (string <= -29) {
        height = 6;
      }
      if (string <= -36) {
        height = 7;
      }
      if (string <= -45) {
        height = 8;
      }
      if (string <= -55) {
        height = 9;
      }
      return `height: ${height}px`;
    },
    updateNeck() {
      // List all frequencies on each string
      const strings = [];
      for (let a = 0; a < this.thisTuning.tuning.length; a += 1) {
        const frets = [];
        const openTone = {
          steps: this.thisTuning.tuning[a],
          index: this.stepFromRef(this.thisTuning.tuning[a], 4).toFixed(5),
        };
        frets.push(openTone);
        // Add subsequent frets
        for (let f = 1; f < this.displayFrets.length; f += 1) {
          const fret = {
            steps: frets[frets.length - 1].steps + 1,
            index: this.stepFromRef(frets[frets.length - 1].steps + 1, 4).toFixed(5),
          };
          frets.push(fret);
        }
        if (this.leftHand) {
          frets.reverse();
        }
        strings.push(frets);
      }
      this.stringsData = strings;
    },
    volumeCalc(hz) {
      let multiplier = 1;
      // Need to increase the volume of low notes
      if (typeof hz === 'number') {
        if (hz < 130) {
          multiplier = 1.33;
        }
        if (hz < 100) {
          multiplier = 1.5;
        }
        if (hz < 80) {
          multiplier = 2.5;
        }
      } else if (hz === 'hihhat') {
        multiplier = 8;
      }
      switch (this.synthVolume) {
        default: break;
        case (0): return 0 * multiplier;
        case (1): return 0.004 * multiplier;
        case (2): return 0.022 * multiplier;
        case (3): return 0.045 * multiplier;
        case (4): return 0.06 * multiplier;
        case (5): return 0.1 * multiplier;
        case (6): return 0.2 * multiplier;
        case (7): return 0.35 * multiplier;
        case (8): return 0.56 * multiplier;
        case (9): return 0.85 * multiplier;
        case (10): return 0.9 * multiplier;
        case (11): return 1.1 * multiplier;
      }
      return 0;
    },
  },
  watch: {
    chromatic() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
    },
    displayFrets() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
    },
    displayMode() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
    },
    instrumentChosen() {
      // when changing instruments, select the default tuning
      if (this.readyToRun) {
        this.tuningChosen = this.tuningOptions[0]._id;
        this.startingOctave = this.octaveOptions[0];
      }
      this.recalculateNotes();
    },
    leftHand() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
    },
    mode() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
      this.startingOctave = this.octaveOptions[0];
    },
    letter() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
      this.startingOctave = this.octaveOptions[0];
    },
    flat() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
      this.startingOctave = this.octaveOptions[0];
    },
    sharp() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
      this.startingOctave = this.octaveOptions[0];
    },
    referenceTone() {
      if (this.readyToRun) {
        this.recalculateNotes();
      }
    },
    showHelp(newVal) {
      setTimeout(() => {
        let target;
        if (newVal) {
          target = document.getElementById('termsOfUse');
        } else {
          target = document.getElementById('topOfPage');
        }
        const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        const dimensions = target.getBoundingClientRect();
        const topOfEl = dimensions.top + window.pageYOffset;
        const scrollDestination = topOfEl - (windowHeight / 6);
        window.scrollTo({ left: 0, top: scrollDestination, behavior: 'smooth' });
      }, 450);
    },
    startingOctave() {
      if (this.readyToRun) {
        this.spanOctaves = 1;
      }
      this.recalculateNotes();
    },
    thisTuning() {
      this.recalculateNotes();
    },
    'thisTuning.tuning': {
      handler() {
        this.recalculateNotes();
      },
    },
    tuningChosen(newVal) {
      if (newVal === 'addingNew') {
        this.$store.dispatch('addTuning', this.instrumentChosen);
        this.customizeTuning = true;
        this.tuningChosen = this.tuningOptions[this.tuningOptions.length - 2]._id;
      }
      this.startingOctave = this.octaveOptions[0];
      this.recalculateNotes();
    },
  },
};
</script>
