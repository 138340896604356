<template>
      <!-- @click="clickAway()" -->
  <b-container
      fluid
      id="app"
      v-on:keydown.27="escButton"
      v-on:keydown.13="enterButton"
      class="main-container">
      <!--'clickAway' dismisses the Invoice Table action buttons. This implementation is subideal. All nested clicks need to be set to click.stop -->
    <nav-bar
        id="nav"
        v-if="$_app.switch.navbar
          && !$_app.switch.bannerAboveNavbar"
    />
    <div
        id="bannerDiv"
        v-if="$_app.switch.banner"
        class="homepage__banner-background">
      <div
          id="bannerContainer"
          style="cursor: pointer"
          class="homepage__banner__container">
        <intro-banner
            id="frontPageBanner"
        />
      </div>
    </div>
    <nav-bar
        id="nav"
        v-if="$_app.switch.navbar
          && $_app.switch.bannerAboveNavbar"
    />
    <transition
        name="fade"
        mode="out-in">
      <router-view
          id="main-view" />
    </transition>
    <!-- <b-toast
        id="info-toast"
        toaster="b-toaster-bottom-left"
        aria-live="passive"
        :auto-hide-delay="systemAction.noName ? 18500 : 1850"
        class="mx-auto"
        :title="toastTitle"
        :variant="toastVariant">
      <div
          class="mx-auto text-center">
        <h4
            class="toast__text">
          <icon
              v-if="toastIcon"
              :icon="toastIcon"
              class="toast__successIcon"
          />
          {{systemAction.msg}}
        </h4>
        <span
            class="toast__text"
            v-text="systemAction.details"
        />
      </div>
    </b-toast>
    <b-toast
        id="error-toast"
        toaster="b-toaster-bottom-right"
        aria-live="assertive"
        :solid="true"
        auto-hide-delay="10000"
        no-auto-hide
        :title="systemAction.msg"
        :variant="restartRequired ? 'success' : 'warning'">
      <b-row
          align="center">
        <b-col
            align="center">
          <span
            v-text="restartRequired ? $t('error.fatal') : systemAction.details"
            class="text-center"
          />
        </b-col>
      </b-row>
      <b-row
          align="center">
        <b-col
            align="center">
          <b-btn
              id="restartApp"
              @click="restartApp()"
              variant="success"
              class="mx-auto mt-3"
              size="lg"
              v-text="$t('error.restartButton')"
          />
          <br />
          <b-btn
              id="retryAction"
              @click="retryAction()"
              variant="warning"
              class="mx-auto mt-3"
              size="sm"
              v-text="$t('error.retry')"
          />
        </b-col>
      </b-row>
    </b-toast> -->
    <foot-bar
        id="footer"
        v-if="$_app.switch.footer"
    />
  </b-container>
</template>

<script>
// import moment from 'moment';

import NavBar from '@/components/util/Navbar';
import FootBar from '@/components/util/Footer';

/* eslint no-param-reassign: off */
export default {
  name: 'App',
  data() {
    return {
      // intro: true,
      // toastIcon: ['fas', 'check-circle'],
      // toastVariant: 'info',
      // toastTitle: this.$t('sys.success'),
    };
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.intro = false;
  //   }, 5600);
  // },
  components: {
    NavBar,
    FootBar,
  },
  computed: {
    restartRequired() {
      return this.$store.state.restartNeeded;
    },
    retryActionData() {
      return this.$store.getters.retry;
    },
    retryActionSuccess() {
      return this.$store.state.retrySuccess;
    },
    systemAction() {
      return this.$store.getters.toastData;
    },
  },
  methods: {
    clickAway() {
      this.$store.dispatch('clickAway');
    },
    escButton() {
      console.log('esc called from App');
      this.$store.state.escButton = true;
    },
    enterButton() {
      console.log('enter called from App');
      this.$store.state.enterButton = true;
    },
    restartApp() {
      this.$store.dispatch('restartApp');
      this.$router.go();
    },
    // retryAction() {
    //   if (this.retryActionData) {
    //     const retry = this.retryActionData;
    //     console.log('retrying action', retry);
    //     if (retry.data) {
    //       this.$store.dispatch(retry.cmd, retry.data);
    //     } else {
    //       this.$store.dispatch(retry.cmd);
    //     }
    //   } else {
    //     console.log('no retry action available');
    //     this.router.go();
    //   }
    // },
  },
  watch: {
    restartNeeded(newVal) {
      if (newVal) {
        this.$router.go();
      }
    },
    // retryActionData(newVal) {
    //   if (newVal) {
    //     console.log('retry requested', newVal);
    //     setTimeout(() => {
    //       if (newVal.data) {
    //         this.$store.dispatch(newVal.cmd, newVal.data);
    //       } else {
    //         this.$store.dispatch(newVal.cmd);
    //       }
    //     }, 1200);
    //     this.toastTitle = this.$t('sys.wait');
    //     this.toastVariant = 'warning';
    //     this.toastIcon = ['fas', 'exclamation-triangle'];
    //     this.$bvToast.show('info-toast');
    //   }
    // },
    // retryActionSuccess(newVal) {
    //   if (newVal) {
    //     this.$bvToast.hide('error-toast');
    //   }
    // },
    // systemAction(incomingData) {
    //   if (incomingData.noName) {
    //     this.toastTitle = this.$t('sys.noName');
    //     this.toastVariant = 'success';
    //     this.toastIcon = ['fas', 'exclamation-triangle'];
    //     this.$bvToast.show('info-toast');
    //   } else if (incomingData.failed) {
    //     if (this.restartRequired) {
    //       this.$bvToast.show('error-toast');
    //       const logData = {
    //         type: 'error',
    //         when: moment().format('l HH:mm'),
    //         project: this.$t('project.url'),
    //         where: this.$route.path,
    //         systemInfo: window.navigator,
    //         errorLog: this.$store.state.errors,
    //         toastData: incomingData,
    //       };
    //       this.$store.dispatch('sendLogToAdmin', logData);
    //     } else {
    //       console.log('re-attempting action in a few moments');
    //       setTimeout(() => {
    //         if (this.retryActionData.data) {
    //           this.$store.dispatch(this.retryActionData.cmd, this.retryActionData.data);
    //         } else {
    //           this.$store.dispatch(this.retryActionData.cmd);
    //         }
    //       }, 300);
    //       if (this.$store.state.errorsCount > 1) {
    //         this.toastIcon = ['fas', 'exclamation-triangle'];
    //         this.toastVariant = 'warning';
    //         this.toastTitle = this.$t('sys.wait');
    //         this.$bvToast.show('info-toast');
    //       }
    //     }
    //   } else if (this.$_pref.showToastOnSuccessfulSave) {
    //     this.toastTitle = this.$t('sys.success');
    //     this.toastVariant = 'success';
    //     this.toastIcon = ['fas', 'check-circle'];
    //     this.$bvToast.show('info-toast');
    //   }
    // },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "./style";
</style>
