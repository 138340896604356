import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
/* import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css'; */
// import VueI18n from 'vue-i18n';
// import JsonCSV from 'vue-json-csv';
import uuid from 'vue-uuid';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import {
//   faBookmark as farBookmark,
//   faCheckSquare,
//   faEdit,
// } from '@fortawesome/free-regular-svg-icons';
// import {
//   faArrowAltCircleDown,
//   faArrowAltCircleUp,
//   faArrowCircleLeft,
//   faArrowCircleRight,
//   faAt,
//   faBookmark as fasBookmark,
//   faCheckCircle,
//   faChevronDown,
//   faChevronRight,
//   faCog,
//   faCopy,
//   faExclamationTriangle,
//   faEye,
//   faFilePdf,
//   faFileDownload,
//   faHome,
//   faLock,
//   faPencilAlt,
//   faPlus,
//   faPrint,
//   faQuestionCircle,
//   faSave,
//   faSearch,
//   faTrashAlt,
//   faTimesCircle,
//   faWindowClose,
// } from '@fortawesome/free-solid-svg-icons';
// import Amplify, * as AmplifyModules from 'aws-amplify';
// import { AmplifyPlugin } from 'aws-amplify-vue';
// import LoadingSpinner from './components/util/Loader';
import App from './App';
import router from './router';
import store from './store/index';
// import translations from './translations';
import titleMixin from './mixins/title';
import breakpoint from './mixins/breakpoint';
// import awsmobile from './aws-exports';
import AppData from './AppData';

// Amplify.configure(awsmobile);

// library.add(
//   faArrowAltCircleDown,
//   faArrowAltCircleUp,
//   faArrowCircleLeft,
//   faArrowCircleRight,
//   faAt,
//   farBookmark,
//   fasBookmark,
//   faCheckCircle,
//   faCheckSquare,
//   faChevronDown,
//   faChevronRight,
//   faCog,
//   faCopy,
//   faEdit,
//   faExclamationTriangle,
//   faEye,
//   faFileDownload,
//   faFilePdf,
//   faHome,
//   faLock,
//   faPencilAlt,
//   faPlus,
//   faPrint,
//   faQuestionCircle,
//   faSearch,
//   faSave,
//   faTrashAlt,
//   faTimesCircle,
//   faWindowClose,
// );

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// Vue.use(VueI18n);
Vue.use(uuid);
// Vue.use(AmplifyPlugin, AmplifyModules);
// Vue.component('icon', FontAwesomeIcon);
// Vue.component('loader', LoadingSpinner);
// Vue.component('downloadCsv', JsonCSV);

Vue.mixin(titleMixin);
Vue.mixin(breakpoint);
Vue.config.productionTip = false;

Vue.prototype.$_app = AppData;
Vue.prototype.$_pref = store.state.preferences;
// eslint-disable-next-line func-names
Vue.prototype.$guid = function () {
  return this.$uuid.v4();
};

// const i18n = new VueI18n({
//   locale: 'en',
//   messages: translations,
// });

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app');
