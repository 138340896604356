/* eslint no-param-reassign: off */
import { v4 as uuid } from 'uuid';

import {
  // ADD_COLLECTION,
  // ADD_COLLECTION_SUCCESS,
  // ADD_CUSTOMER,
  // ADD_CUSTOMER_SUCCESS,
  // ADD_FIELD,
  // ADD_FIELD_SELECT_OPTION,
  // ADD_INVOICE,
  // ADD_INVOICE_SUCCESS,
  // ADD_INVOICE_SUCCESS_MULTI,
  // ADD_LINKED_RECORD_SUCCESS,
  // ADD_RECORD,
  // ADD_RECORD_SUCCESS,
  // ADD_WORKDAY,
  // ADDITIONAL_RECORDS_PROCESSING,
  // AUTOSAVE_COMPLETE,
  // AUTOSAVE_NOW,
  // AUTOSAVE_REQUEST,
  // ALL_CUSTOMERS,
  // ALL_CUSTOMERS_SUCCESS,
  // ALL_INVOICES,
  // ALL_INVOICES_SUCCESS,
  // CLEAR_ERRORS,
  // CLEAR_LOGO,
  // CLICK_AWAY,
  // CLOCKED_IN,
  // CLOCKED_OUT,
  // CLOSE_PREFERENCES,
  // CLOSE_PREFERENCES_CLEAR,
  // CUSTOMER_BY_ID,
  // CUSTOMER_BY_ID_SUCCESS,
  // DBVERSION_INC,
  // DBVERSION_CLEAR,
  // DBVERSION_MISMATCH,
  // FILE_UPLOADED,
  // FILE_UPLOAD_CLEAR,
  // GET_COLLECTION,
  // GET_COLLECTION_SUCCESS,
  // GET_RECORDS_SUCCESS,
  // GET_WORKDAYS_SUCCESS,
  // HIDE_SPINNER,
  // HIDE_SPINNER_BY_ID,
  // INITIAL_LOAD,
  // INITIAL_LOAD_SUCCESS,
  // INVOICE_BY_ID,
  // INVOICE_BY_ID_SUCCESS,
  // INVOICE_PRINTDATA,
  // INVOICE_PRINTDATA_SUCCESS,
  // INVOICED_ON_ADD,
  // INVOICED_ON_REMOVE,
  // INVOICED_ON_SUCCESS,
  // ITEM_REFRESH,
  // ITEM_REFRESH_DONE,
  // LAUNCH_PREVIEW,
  // LAUNCH_PREVIEW_CLEAR,
  // LOAD_ACCOUNT,
  // LOAD_ACCOUNT_SUCCESS,
  // LOAD_PREFERENCES,
  // LOAD_PREFERENCES_SUCCESS,
  // NO_NAME,
  // REMOVE_COLLECTION,
  // REMOVE_COLLECTION_SUCCESS,
  // REMOVE_CUSTOMER,
  // REMOVE_CUSTOMER_SUCCESS,
  // REMOVE_INVOICE,
  // REMOVE_INVOICE_SUCCESS,
  // REMOVE_RECORD,
  // REMOVE_RECORD_SUCCESS,
  // REMOVE_RECORDS,
  // REMOVE_RECORDS_SUCCESS,
  // REMOVE_TIMECARD_ENTRY,
  // REMOVE_TIMECARD_ENTRY_SUCCESS,
  // REMOVE_WORKDAY,
  // SET_PREFERENCES,
  // SET_CUSTOMER_PREFERENCES,
  // SET_PREFERENCES_SORT,
  // SHOW_INFO_TOAST,
  // SHOW_ITEM,
  // SHOW_ITEM_CLEAR,
  // SHOW_ERROR,
  // SHOW_SPINNER,
  // SHOW_SPINNER_BY_ID,
  // SIGNED_IN,
  // SIGNED_OUT,
  // SUBMIT_FORM,
  // SUBMIT_FORM_SUCCESS,
  // SUBMIT_TIMECARD,
  // SUBMIT_TIMECARD_SUCCESS,
  // TIMECARD_HISTORY,
  // UPDATE_ACCOUNT,
  // UPDATE_ACCOUNT_SUCCESS,
  // UPDATE_COLLECTION,
  // UPDATE_COLLECTION_SUCCESS,
  // UPDATE_CUSTOMER,
  // UPDATE_CUSTOMER_SUCCESS,
  // UPDATE_INVOICE,
  // UPDATE_INVOICE_SUCCESS,
  // UPDATE_INVOICES,
  // UPDATE_INVOICES_SUCCESS,
  // UPDATE_RECORD,
  // UPDATE_RECORD_SUCCESS,
  // UPDATE_QTY,
  // UPDATE_QTY_SUCCESS,
  // UPDATE_WORKDAY,
  // ZIP_REQUEST,
  // ZIP_REQUEST_DONE,
  // ZIP_RESULTS,
  // ZIP_RESULTS_ERROR,
  addTuning,
  chromatic,
  arpDuration,
  chordDuration,
  clickIn,
  descend,
  displayMode,
  hideNoteNames,
  instrumentChosen,
  leftHand,
  keyOf,
  mode,
  oscillatorType,
  referenceTone,
  repeats,
  repeatTonic,
  showAllNoteNames,
  showAllNoteNamesWhenPlaying,
  spanOctaves,
  startingOctave,
  synthVolume,
  tuningChosen,
  letter,
  sharp,
  flat,
  tuningOptions,
  adjustTuning,
  thisTuning,
  stringsData,
} from './mutation-types';


// eslint-disable-next-line import/prefer-default-export
export const dataMutations = {
  [addTuning](state, instrument) {
    console.log('tuning');
    const tuning = {
      _id: uuid(),
      label: 'New tuning',
      tuning: [...state.chords.tuningOptions[instrument][0].tuning],
    };
    if (state.chords.tuningOptions[instrument][0].fretless) {
      tuning.fretless = true;
    }
    state.chords.tuningOptions[instrument].push(tuning);
    console.log(state.chords.tuningOptions[instrument]);
    state.chords.tuningChosen = tuning._id;
  },
  [adjustTuning](state, { up, string }) {
    if (up) {
      state.chords.tuningOptions[state.chords.instrumentChosen].filter(i => i._id === state.chords.tuningChosen)[0].tuning[string] += 1;
    } else {
      state.chords.tuningOptions[state.chords.instrumentChosen].filter(i => i._id === state.chords.tuningChosen)[0].tuning[string] -= 1;
    }
  },
  [arpDuration](state, newVal) {
    state.chords.arpDuration = newVal;
  },
  [chordDuration](state, newVal) {
    state.chords.chordDuration = newVal;
  },
  [chromatic](state, newVal) {
    state.chords.chromatic = newVal;
  },
  [clickIn](state, newVal) {
    state.chords.clickIn = newVal;
  },
  [descend](state, newVal) {
    state.chords.descend = newVal;
  },
  [displayMode](state, newVal) {
    state.chords.displayMode = newVal;
  },
  [hideNoteNames](state, newVal) {
    state.chords.hideNoteNames = newVal;
  },
  [instrumentChosen](state, newVal) {
    state.chords.instrumentChosen = newVal;
  },
  [leftHand](state, newVal) {
    state.chords.leftHand = newVal;
  },
  [keyOf](state, newVal) {
    state.chords.keyOf = newVal;
  },
  [mode](state, newVal) {
    state.chords.mode = newVal;
  },
  [oscillatorType](state, newVal) {
    state.chords.oscillatorType = newVal;
  },
  [referenceTone](state, newVal) {
    state.chords.referenceTone = newVal;
  },
  [repeats](state, newVal) {
    state.chords.repeats = newVal;
  },
  [repeatTonic](state, newVal) {
    state.chords.repeatTonic = newVal;
  },
  [showAllNoteNames](state, newVal) {
    state.chords.showAllNoteNames = newVal;
  },
  [showAllNoteNamesWhenPlaying](state, newVal) {
    state.chords.showAllNoteNamesWhenPlaying = newVal;
  },
  [spanOctaves](state, newVal) {
    state.chords.spanOctaves = newVal;
  },
  [startingOctave](state, newVal) {
    state.chords.startingOctave = newVal;
  },
  [synthVolume](state, newVal) {
    state.chords.synthVolume = newVal;
  },
  [tuningChosen](state, newVal) {
    state.chords.tuningChosen = newVal;
  },
  [letter](state, newVal) {
    state.chords.letter = newVal;
  },
  [sharp](state, newVal) {
    state.chords.sharp = newVal;
  },
  [flat](state, newVal) {
    state.chords.flat = newVal;
  },
  [tuningOptions](state, newVal) {
    state.chords.tuningOptions = newVal;
  },
  [thisTuning](state, newVal) {
    state.chords.tuningOptions[state.chords.instrumentChosen].filter(i => i._id === state.chords.tuningChosen)[0] = newVal;
  },
  [stringsData](state, newVal) {
    state.chords.stringsData = newVal;
  },
  //   [ADD_RECORD](state) {
  //     console.log('adding record');
  //     state.showSpinner = true;
  //   },
  //   [ADD_RECORD_SUCCESS](state, payload) {
  //     state.showSpinner = false;
  //     if (!state.data.records[payload.collection]) {
  //       state.data.records[payload.collection] = [];
  //     }
  //     state.data.records[payload.collection].push(payload.record);
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ADD_LINKED_RECORD_SUCCESS](state, payload) {
  //     if (state.data.records[payload.collection]) {
  //       state.data.records[payload.collection].push(payload.record);
  //     } else {
  //       state.data.records[payload.collection] = [payload.record];
  //     }
  //     state.linkedRecordSaved = {
  //       requestId: payload.requestId, // field key
  //       recordId: payload.record._id,
  //       collectionId: payload.record._collection,
  //     };
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ADD_CUSTOMER](state) {
  //     console.log('adding customer');
  //     state.showSpinner = true;
  //     state.data.customerHasBeenAdded = false;
  //   },
  //   [ADD_CUSTOMER_SUCCESS](state, payload) {
  //     state.data.customers.push(payload);
  //     state.data.customerHasBeenAdded = payload._id;
  //     state.showSpinner = false;
  //     console.log('added customer');
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ADD_INVOICE](state) {
  //     state.showSpinner = true;
  //     console.log('saving invoice');
  //   },
  //   [ADD_INVOICE_SUCCESS](state, payload) {
  //     state.showSpinner = false;
  //     console.log('invoice saved!');
  //     state.data.invoices.push(payload);
  //     state.showItem = payload._id;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ADD_INVOICE_SUCCESS_MULTI](state, payload) {
  //     state.showSpinner = false;
  //     state.data.invoices.push(payload[0]);
  //     state.showItem = payload[0]._id;
  //     for (let a = 1; a < payload.length; a += 1) {
  //       const index = state.data.invoices.findIndex(invoice => invoice._id === payload[a]._id);
  //       if (index > -1) {
  //         state.data.invoices.splice(index, 1, payload[a]);
  //       } else {
  //         state.data.invoices.push(payload[a]);
  //       }
  //     }
  //     state.spinnerById = null;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ADDITIONAL_RECORDS_PROCESSING](state, payload) {
  //     for (let a = 0; a < payload.length; a += 1) {
  //       const index = state.data.records[payload[a]._collection].findIndex(i => i._id === payload[a]._id);
  //       if (index > -1) {
  //         state.data.records[payload[a]._collection].splice(index, 1, payload[a]);
  //       } else {
  //         state.data.records[payload[a]._collection].push(payload[a]);
  //       }
  //     }
  //   },
  //   [ALL_CUSTOMERS](state) {
  //     state.showLoader = true;
  //     console.log('fetching customers');
  //   },
  //   [ALL_CUSTOMERS_SUCCESS](state, payload) {
  //     state.showLoader = false;
  //     state.showSpinner = false;
  //     if (!payload.versionMatch) {
  //       state.data.customers = payload;
  //       console.log('customers fetched');
  //     } else {
  //       console.log('customer data already matches local data');
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ALL_INVOICES](state) {
  //     state.showLoader = true;
  //     console.log('fetching invoices');
  //   },
  //   [ALL_INVOICES_SUCCESS](state, payload) {
  //     console.log('invoices fetched');
  //     state.showLoader = false;
  //     state.showSpinner = false;
  //     if (payload.partialUpdate) {
  //       const docs = payload.partialUpdate;
  //       for (let a = 1; a < docs.length; a += 1) {
  //         const index = state.data.invoices.findIndex(invoice => invoice._id === docs[a]._id);
  //         if (index > -1) {
  //           state.data.invoices.splice(index, 1, docs[a]);
  //         } else {
  //           state.data.invoices.push(docs[a]);
  //         }
  //       }
  //     } else if (payload.newData) {
  //       state.data.invoices = payload.newData;
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [CUSTOMER_BY_ID](state) {
  //     state.showLoader = true;
  //     console.log('fetching customer');
  //   },
  //   [CUSTOMER_BY_ID_SUCCESS](state, payload) {
  //     state.showLoader = false;
  //     state.spinnerById = null;
  //     if (!payload.versionMatch) {
  //       const index = state.data.customers.findIndex(cust => cust._id === payload._id);
  //       state.data.customers.splice(index, 1, payload);
  //       console.log('customer fetched');
  //     } else {
  //       console.log('customer data already exists');
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [DBVERSION_CLEAR](state) {
  //     state.dbversion = 0;
  //     console.log('database version cleared');
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [DBVERSION_INC](state, payload) {
  //     state.dbversion = payload;
  //     console.log('database version updated');
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [DBVERSION_MISMATCH](state) {
  //     console.log('mismatch detected');
  //     state.showToast = {
  //       failed: true,
  //       msg: 'Version Mismatch',
  //       // eslint-disable-next-line max-len
  //       details: 'Your machine\'s local data is out of sync with the cloud.
  //                This usually happens when multiple people are working on the system simultaneously. \nPlease restart the application.',
  //       restartRequired: true,
  //       retry: false,
  //     };
  //     state.dbversionMismatch = true;
  //   },
  //   [INITIAL_LOAD](state, idToken) {
  //     state.showLoader = true;
  //     state.data.userId = idToken;
  //     console.log('Initializing...');
  //   },
  //   [INITIAL_LOAD_SUCCESS](state) {
  //     console.log('Initial load complete!');
  //     state.showLoader = false;
  //     state.showSpinner = false;
  //     state.initialLoad = true;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [INVOICE_BY_ID](state) {
  //     state.showLoader = true;
  //     console.log('fetching invoice');
  //   },
  //   [INVOICE_BY_ID_SUCCESS](state, payload) {
  //     state.showLoader = false;
  //     if (!payload.versionMatch) {
  //       state.data.invoice = payload;
  //       const index = state.data.invoices.findIndex(invoice => invoice._id === payload._id);
  //       state.data.invoices.splice(index, 1, payload);
  //       console.log('invoice fetched');
  //     } else {
  //       console.log('local data matches server data');
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [INVOICE_PRINTDATA](state, payload) {
  //     state.spinnerById = payload;
  //     console.log('fetching invoice for printData');
  //   },
  //   [INVOICE_PRINTDATA_SUCCESS](state, payload) {
  //     state.data.printData = null;
  //     state.data.printData = payload;
  //     state.spinnerById = null;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ITEM_REFRESH](state) {
  //     state.refreshNeeded = true;
  //   },
  //   [ITEM_REFRESH_DONE](state) {
  //     state.refreshNeeded = false;
  //   },
  //   [NO_NAME](state) {
  //     state.showToast = {
  //       noName: true,
  //       msg: 'Please enter a name for the Sender.',
  //       // eslint-disable-next-line max-len
  //       details: 'Click the Settings⚙️ button, then click Contact Details.',
  //       restartRequired: true,
  //       retry: false,
  //     };
  //   },
  //   [REMOVE_INVOICE](state) {
  //     console.log('deleting invoice');
  //     state.showSpinner = true;
  //   },
  //   [REMOVE_INVOICE_SUCCESS](state, payload) {
  //     console.log('invoice has been removed');
  //     state.showSpinner = false;
  //     const index = state.data.invoices.findIndex(p => p._id === payload.removed);
  //     state.data.invoices.splice(index, 1);
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [REMOVE_CUSTOMER](state) {
  //     console.log('removing customer');
  //     state.showSpinner = true;
  //   },
  //   [REMOVE_CUSTOMER_SUCCESS](state, payload) {
  //     state.showSpinner = false;
  //     const invoicesToRemove = [];
  //     state.data.invoices.filter(i => i.customer === payload.removed).forEach((doc) => {
  //       invoicesToRemove.push(doc._id);
  //     });
  //     console.log('customer had this many invoices', invoicesToRemove.length);
  //     if (invoicesToRemove.length) {
  //       for (let a = 0; a < invoicesToRemove.length; a += 1) {
  //         console.log('need to remove');
  //         state.data.invoices.splice(invoicesToRemove[a], 1);
  //       }
  //     }
  //     const index = state.data.customers.findIndex(p => p._id === payload.removed);
  //     state.data.customers.splice(index, 1);
  //     console.log('customer removed');
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [REMOVE_RECORD](state) {
  //     console.log('deleting record');
  //     state.showSpinner = true;
  //   },
  //   [REMOVE_RECORD_SUCCESS](state, payload) {
  //     const index = state.data.records[payload.collection].findIndex(p => p._id === payload.record);
  //     state.data.records[payload.collection].splice(index, 1);
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //     state.showSpinner = false;
  //   },
  //   [REMOVE_RECORDS](state) {
  //     console.log('deleting record');
  //     state.showSpinner = true;
  //   },
  //   [REMOVE_RECORDS_SUCCESS](state, payload) {
  //     const records = payload.records.split(',');
  //     for (let a = 0; a < records.length; a += 1) {
  //       const recordId = records[a];
  //       const index = state.data.records[payload.collection].findIndex(i => i._id === recordId);
  //       state.data.records[payload.collection].splice(index, 1);
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //     console.log('Records have been removed');
  //     state.showSpinner = false;
  //   },
  //   [SHOW_ITEM](state, payload) {
  //     state.showItem = payload;
  //   },
  //   [SHOW_ITEM_CLEAR](state) {
  //     state.showItem = null;
  //   },
  //   [UPDATE_CUSTOMER]() {
  //     console.log('updating customer');
  //   },
  //   [UPDATE_CUSTOMER_SUCCESS](state) {
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //     console.log('customer updated');
  //   },
  //   [UPDATE_INVOICE](state, payload) {
  //     console.log('updating invoice');
  //     state.spinnerById = payload;
  //   },
  //   [UPDATE_INVOICE_SUCCESS](state) {
  //     console.log('invoice has been updated');
  //     state.spinnerById = null;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [UPDATE_INVOICES](state) {
  //     console.log('updating multiple invoices');
  //     state.showSpinner = true;
  //   },
  //   [UPDATE_INVOICES_SUCCESS](state) {
  //     state.showSpinner = false;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [UPDATE_RECORD](state, payload) {
  //     console.log('updating record');
  //     state.spinnerById = payload;
  //   },
  //   [UPDATE_RECORD_SUCCESS](state, payload) {
  //     const index = state.data.records[payload.collection].findIndex(i => i._id === payload.record._id);
  //     state.data.records[payload.collection].splice(index, 1, payload.record);
  //     state.spinnerById = null;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [INVOICED_ON_ADD](state, payload) {
  //     state.spinnerById = payload.invoice;
  //     const docIndex = state.data.records[payload.collection].findIndex(i => i._id === payload.record);
  //     state.data.records[payload.collection][docIndex].invoicedIn.push({
  //       date: new Date(),
  //       document: payload.invoice,
  //       lineItem: payload.lineItem,
  //     });
  //   },
  //   [INVOICED_ON_REMOVE](state, payload) {
  //     state.spinnerById = payload.invoice;
  //     const docIndex = state.data.records[payload.collection].findIndex(i => i._id === payload.record);
  //     const linkIndex = state.data.records[payload.collection][docIndex].invoicedIn.findIndex(i => (i.document === payload.invoice && i.lineItem === payload.lineItem));
  //     if (linkIndex > -1) {
  //       state.data.records[payload.collection][docIndex].invoicedIn.splice(linkIndex, 1);
  //     }
  //   },
  //   [INVOICED_ON_SUCCESS](state) {
  //     state.spinnerById = null;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [UPDATE_QTY](state, payload) {
  //     state.spinnerById = payload.record;
  //     const index = state.data.records[payload.collection].findIndex(i => i._id === payload.record);
  //     const difference = payload.newQty - payload.originalQty;
  //     const pullReceipt = {
  //       lineItem: payload.lineItem,
  //       invoice: payload.invoice,
  //       date: new Date(),
  //       change: difference,
  //     };
  //     console.log('starting out, it is ', state.data.records[payload.collection][index][payload.field].counter);
  //     state.data.records[payload.collection][index][payload.field].counter -= difference;
  //     state.data.records[payload.collection][index][payload.field].invoicePulls.push(pullReceipt);
  //     console.log('now it is ', state.data.records[payload.collection][index][payload.field].counter);
  //     state.data.records[payload.collection][index].dateUpdated = new Date();
  //   },
  //   [UPDATE_QTY_SUCCESS](state) {
  //     state.spinnerById = null;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  // };

  // export const utilityMutations = {
  //   [ADD_COLLECTION](state) {
  //     console.log('adding collection');
  //     state.showSpinner = true;
  //   },
  //   [ADD_COLLECTION_SUCCESS](state, { newColl, databases }) {
  //     state.data.collections = databases;
  //     state.data.records[newColl] = [];
  //     state.collectionsChangedRestart = true;
  //     state.showSpinner = false;
  //     console.log('added collection');
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //     console.log('collection has been added', state.collectionsChangedRestart);
  //   },
  //   [ADD_FIELD](state, payload) {
  //     const i = state.data.collections.findIndex(c => c._id === payload.collection);
  //     if (!state.data.collections[i]) {
  //       return;
  //     }
  //     if (!state.data.collections[i].schemaObj) {
  //       state.data.collections[i].schemaObj = {};
  //     }
  //     state.data.collections[i].schemaObj[payload.fieldId] = payload.fieldData;
  //   },
  //   [ADD_FIELD_SELECT_OPTION](state, payload) {
  //     const i = state.data.collections.findIndex(c => c._id === payload.collection);
  //     if (!state.data.collections[i]) {
  //       return;
  //     }
  //     state.data.collections[i].schemaObj[payload.fieldId].default = {
  //       ...state.data.collections[i].schemaObj[payload.fieldId].default,
  //       [payload.optionId]: {
  //         type: 'Boolean',
  //         value: false,
  //         label: payload.label,
  //       },
  //     };
  //   },
  //   [AUTOSAVE_REQUEST](state) {
  //     state.autosaveTriggered = true;
  //   },
  //   [AUTOSAVE_COMPLETE](state) {
  //     state.autosaveTriggered = false;
  //   },
  //   [AUTOSAVE_NOW](state) {
  //     console.log('CALLUNG AUTOSAVE');
  //     state.autosaveNow = true;
  //   },
  //   [UPDATE_COLLECTION](state) {
  //     state.showSpinner = true;
  //   },
  //   [UPDATE_COLLECTION_SUCCESS](state, payload) {
  //     console.log('updated collection');
  //     state.showSpinner = false;
  //     state.data.collections = payload;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [REMOVE_COLLECTION](state) {
  //     state.showSpinner = false;
  //     // const i = state.data.collections.findIndex(coll => coll._id === payload);
  //     // state.data.collections.splice(i, 1);
  //   },
  //   [REMOVE_COLLECTION_SUCCESS](state, payload) {
  //     state.showSpinner = false;
  //     state.data.collections = payload;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [GET_COLLECTION](state) {
  //     state.showSpinner = true;
  //     console.log('fetching invoice');
  //   },
  //   [GET_COLLECTION_SUCCESS](state, payload) {
  //     state.showSpinner = false;
  //     if (!payload.versionMatch) {
  //       state.data.collections = payload;
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [GET_RECORDS_SUCCESS](state, payload) {
  //     state.showLoader = false;
  //     state.showSpinner = false;
  //     if (!payload.versionMatch) {
  //       state.data.records = JSON.parse(JSON.stringify(payload));
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [CLEAR_LOGO](state) {
  //     state.logo = {
  //       location: null,
  //       data: null,
  //     };
  //   },
  //   [CLEAR_ERRORS](state) {
  //     state.errors = [];
  //   },
  //   [CLICK_AWAY](state) {
  //     state.showItem = null;
  //   },
  //   [CLOSE_PREFERENCES](state) {
  //     state.needToClosePreferences = true;
  //   },
  //   [CLOSE_PREFERENCES_CLEAR](state) {
  //     state.needToClosePreferences = false;
  //   },
  //   [FILE_UPLOADED](state, payload) {
  //     state.fileUploadToDelete = payload;
  //   },
  //   [FILE_UPLOAD_CLEAR](state) {
  //     state.fileUploadToDelete = null;
  //   },
  //   [LAUNCH_PREVIEW](state, payload) {
  //     state.launchPreview = null;
  //     state.launchPreview = payload;
  //   },
  //   [LAUNCH_PREVIEW_CLEAR](state) {
  //     state.launchPreview = null;
  //   },
  //   [LOAD_ACCOUNT](state) {
  //     console.log('loading user account settings');
  //     state.showSpinner = true;
  //   },
  //   [LOAD_ACCOUNT_SUCCESS](state, payload) {
  //     console.log('account settings have been loaded!');
  //     state.account = payload;
  //     state.showSpinner = false;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [LOAD_PREFERENCES](state) {
  //     state.showSpinner = true;
  //   },
  //   [LOAD_PREFERENCES_SUCCESS](state, payload) {
  //     console.log('preferences have been loaded!');
  //     state.preferences = payload;
  //     state.showSpinner = false;
  //     // For adding newly created fields in old accounts
  //     if (!state.preferences.defaultLabelInvoice) {
  //       state.preferences.defaultLabelInvoice = 'statement';
  //     }
  //     if (!state.preferences.defaultLabelProposal) {
  //       state.preferences.defaultLabelProposal = 'proposal';
  //     }
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [SET_PREFERENCES](state, payload) {
  //     state.preferences = payload;
  //   },
  //   [SET_CUSTOMER_PREFERENCES](state, payload) {
  //     const index = state.data.customers.findIndex(p => p._id === payload.customerId);
  //     state.data.customers[index].preferences = payload.prefs;
  //   },
  //   [SET_PREFERENCES_SORT](state, payload) {
  //     state.preferences.defaultSort = payload;
  //   },
  //   [SHOW_ERROR](state, payload) {
  //     if (state.restartNeeded) {
  //       state.fileAction = payload;
  //       state.errorsCount += 1;
  //       console.log(state.errorsCount, 'error count');
  //       state.showToast = {
  //         failed: true,
  //         msg: `We're having trouble ${payload.action}`,
  //         details: payload.error,
  //         restartRequired: true,
  //         retry: false,
  //       };
  //       state.retry.cmd = payload.cmd.run;
  //       state.retry.data = payload.cmd.data;
  //     } else if (!state.restartNeeded) {
  //       console.log('going to retry in a moment');
  //       state.showToast = {
  //         failed: true,
  //         msg: `We're having trouble ${payload.action}`,
  //         details: `${payload.error}`,
  //         cmd: payload.cmd.run,
  //         restartRequired: false,
  //         retry: true,
  //       };
  //       state.retry.cmd = payload.cmd.run;
  //       state.retry.data = payload.cmd.data;
  //       state.errorsCount += 1;
  //       if (state.errorsCount > 2) {
  //         state.restartNeeded = true;
  //       }
  //     }
  //     state.spinnerById = null;
  //     state.showLoader = false;
  //     state.showSpinner = false;
  //   },
  //   [SHOW_INFO_TOAST](state, payload) {
  //     state.showToast = payload;
  //   },
  //   [SHOW_SPINNER](state) {
  //     state.showSpinner = true;
  //   },
  //   [SHOW_SPINNER_BY_ID](state, payload) {
  //     state.spinnerById = payload;
  //   },
  //   [HIDE_SPINNER](state) {
  //     state.showSpinner = false;
  //   },
  //   [HIDE_SPINNER_BY_ID](state) {
  //     state.spinnerById = null;
  //   },
  //   [SIGNED_IN](state, payload) {
  //     state.signedIn = true;
  //     state.user = payload;
  //     state.preferences._id = payload.attributes.sub;
  //     state.showLoader = false;
  //     console.log('signed in:', state.user.attributes.email);
  //   },
  //   [SIGNED_OUT](state) {
  //     state.signedIn = false;
  //     state.user = null;
  //     console.log('signed out');
  //   },
  //   [SUBMIT_FORM](state) {
  //     console.log('submitting form');
  //     state.showSpinner = true;
  //   },
  //   [SUBMIT_FORM_SUCCESS](state) {
  //     state.sendFileSuccess = true;
  //     state.showSpinner = false;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [UPDATE_ACCOUNT](state, payload) {
  //     switch (payload.type) {
  //       default: break;
  //       case ('employees'): {
  //         state.account.employees = payload.id;
  //         state.account.employeesFields = payload.fields;
  //         break;
  //       }
  //       case ('people'): {
  //         state.account.people = payload.id;
  //         break;
  //       }
  //       case ('services'): {
  //         state.account.services = payload.id;
  //         break;
  //       }
  //     }
  //     state.showSpinner = true;
  //   },
  //   [UPDATE_ACCOUNT_SUCCESS](state) {
  //     console.log('account settings have been updated!');
  //     state.showSpinner = false;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [ZIP_REQUEST](state, { id, zip }) {
  //     state.zipRequest = id;
  //     state.zipLookup.zip = zip;
  //   },
  //   [ZIP_REQUEST_DONE](state) {
  //     state.zipLookup = {
  //       id: null,
  //       city: null,
  //       state: null,
  //       zip: null,
  //     };
  //   },
  //   [ZIP_RESULTS](state, payload) {
  //     console.log('zip results', payload);
  //     state.zipLookup.city = payload.city;
  //     state.zipLookup.state = payload.state;
  //     state.zipLookup.zip = payload.zip;
  //     state.zipLookup.id = state.zipRequest;
  //   },
  //   [ZIP_RESULTS_ERROR](state, payload) {
  //     state.zipLookup.id = 'err';
  //     console.log('error', payload);
  //   },
  // };

  // export const workdayMutations = {
  //   [ADD_WORKDAY](state, payload) {
  //     state.showSpinner = false;
  //     state.data.workDays.push(payload);
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [CLOCKED_IN](state, payload) {
  //     state.data.workDayTimecards.push(payload.shiftLogin);
  //     if (payload.afterLunch) {
  //       state.data.workDayTimecards.push(payload.afterLunch);
  //     }
  //     state.showSpinner = false;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [CLOCKED_OUT](state, payload) {
  //     const index = state.data.workDayTimecards.findIndex(i => i._id === payload.shiftLogin._id);
  //     state.data.workDayTimecards.splice(index, 1, payload.shiftLogin);
  //     if (payload.afterLunch) {
  //       state.data.workDayTimecards.splice(index + 1, 0, payload.afterLunch);
  //     }
  //     state.showSpinner = false;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [GET_WORKDAYS_SUCCESS](state, payload) {
  //     state.showSpinner = false;
  //     state.data.workDays = payload;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [REMOVE_TIMECARD_ENTRY](state) {
  //     state.showSpinner = true;
  //   },
  //   [REMOVE_TIMECARD_ENTRY_SUCCESS](state, payload) {
  //     state.showSpinner = false;
  //     const index = state.data.workDayTimecards.findIndex(p => p._id === payload);
  //     state.data.workDayTimecards.splice(index, 1);
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [SUBMIT_TIMECARD](state) {
  //     state.showSpinner = true;
  //   },
  //   [SUBMIT_TIMECARD_SUCCESS](state) {
  //     state.showSpinner = false;
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [REMOVE_WORKDAY](state, payload) {
  //     const index = state.data.workDays.findIndex(p => p._id === payload);
  //     state.data.workDays.splice(index, 1);
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  //   },
  //   [TIMECARD_HISTORY](state, payload) {
  //     state.data.workDayTimecards = payload;
  //   },
  //   [UPDATE_WORKDAY](state, payload) {
  //     const index = state.data.workDays.findIndex(p => p._id === payload._id);
  //     state.data.workDays.splice(index, 1, payload);
  //     if (state.errorsCount > 0) {
  //       state.errorsCount = 0;
  //       state.retrySuccess = true;
  //     }
  // },
};
