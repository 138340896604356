<!-- Based on websanova/vue-knob -->
<template>
    <div
        class="knob"
        v-bind:class="{
            'disabled': disabled,
            'dragging': isDragging,
            ['knob-' + size]: size,
            ['knob-' + variant]: variant,
        }"
        @click="$emit('click')"
    >
        <div
            v-for="(option, index) in _options"
            :key="index"
            class="knob-label-anchor"
            v-bind:class="[
                'knob-label-anchor-' + option.angle,
                'knob-label-' + option.labelPosition,
                getLabelActive(index) ? 'active' : '',
                getLabelHover(index) ? 'hover' : '',
            ]"
            v-bind:style="{
                transform: 'rotate(' + option.angle + 'deg)'
            }"
        >
            <div
                v-if="option.label !== false"
                class="knob-label"
                v-bind:style="{
                    transform: 'rotate(-' + option.angle + 'deg)'
                }"
                v-on:click.stop="onClickLabel(index)"
            >
                <div
                    v-html="option.label"
                />
            </div>
        </div>

        <div
            class="knob-dial"
            v-on:mousedown="onDragStart"
            v-on:touchstart="onDragStart"
            v-bind:style="{
                transform: 'rotate('  + anchorAngle + 'deg)',
                '--knob-anchor-angle-transition-distance': anchorAngleDistance
            }"
        >
            <slot
                name="dial"
            />

            <div
                :id="'knob-dial-anchor-' + _id"
                class="knob-dial-anchor"
            >
                <div
                    class="knob-dial-pointer"
                />
            </div>
        </div>

        <slot />
    </div>
</template>

<script>
export default {
  name: 'VueKnob',
  props: {
    value: {
      default: null,
    },
    variant: {
      default: '',
    },
    size: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      default() {
        return [];
      },
    },
    valueKey: {
      default: 'value',
    },
    labelKey: {
      default: 'label',
    },
    startAngle: {
      type: Number,
      default: 30,
    },
    endAngle: {
      type: Number,
      default: 330,
    },
    skipAngle: {
      type: Number,
      default: 150,
    },
    anchorOffset: {
      type: Number,
      default: 0,
    },
    minSpeed: {
      type: Number,
      default: 0.2,
    },
    slider: {
      type: Boolean,
      default: false,
    },
    sliderSnapTo: {
      type: Boolean,
      default: false,
    },
    sliderStepBy: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      valueInternal: null,

      anchorAngle: 0,
      anchorAngleDistance: 0,
      isDragging: false,
      drag: {},
    };
  },
  computed: {
    _id() {
      return Math.random();
    },
    _rotation() {
      const degrees = this.endAngle - this.startAngle;
      return degrees / (this.options.length - 1);
    },
    _index() {
      let option;
      let index = null;
      if (!this.slider) {
        for (let i = 0; i < this._options.length; i += 1) {
          option = this._options[i];
          if (option.value === this.value
          || (option.value !== undefined
              && this.value !== undefined
              && this.value !== null
              && option.value === this.value[this.valueKey])) {
            index = i;
            break;
          }
        }
      }
      return index;
    },
    _indexHover() {
      return this.getIndexActive(this.anchorAngle);
    },
    _options() {
      const options = [];
      for (let i = 0; i < this.options.length; i += 1) {
        const option = this.formatOption(i);
        if (option.anchor) {
          options.push(option);
        }
      }
      return options;
    },
  },
  watch: {
    _index(val) {
      if (this._options[val]) {
        this.setAnchorAngle(this._options[val].angle + this.anchorOffset);
      }
    },
    value(val) {
      if (
        this.slider
                    && (this.sliderSnapTo || val !== this.valueInternal)
      ) {
        this.processAngle(this.processValue(val), true);
      }
      this.valueInternal = val;
    },
  },
  mounted() {
    this.valueInternal = this.value;
    if (!this.slider) {
      this.setAnchorAngle(((this._options[this._index] || {}).angle || 0) + this.anchorOffset);
    } else {
      this.processAngle(this.processValue(this.value));
    }
  },
  methods: {
    toggle(clicked) {
      let option;
      if (this.disabled) {
        return;
      }
      let index;
      if (clicked === undefined) {
        index = this.options[this._index + 1] ? this._index + 1 : 0;
      } else {
        index = clicked;
      }
      if (index !== this._index) {
        option = this._options[index] || this._options[0];
        this.setAnchorAngle(option.angle);
        this.$emit('input', !this.slider ? option.original : option.value);
      }
    },
    formatOption(i) {
      const value = this.options[i][this.valueKey] !== undefined ? this.options[i][this.valueKey] : this.options[i];
      const angle = this.options[i].angle !== undefined ? this.options[i].angle : this.startAngle + (Math.round(this._rotation * i * 100) / 100);
      return {
        value,
        angle,
        label: this.options[i][this.labelKey] !== undefined ? this.options[i][this.labelKey] : value,
        anchor: !((this.options[i].anchor === false || (this.options[i][this.labelKey] === false && this.options[i].anchor !== true))),
        labelPosition: this.getLabelPosition(angle),
        original: this.options[i],
      };
    },
    getLabelPosition(angle) {
      if (angle > 0 && angle < 180) {
        return 'left';
      }
      if (angle < 360 && angle > 180) {
        return 'right';
      }

      return 'center';
    },
    getLabelActive(index) {
      return index === this._index;
    },
    getLabelHover(index) {
      return index === this._indexHover;
    },
    getIndexActive(angle) {
      let i;
      for (i = 0; i < this._options.length; i += 1) {
        if (this._options[i + 1] && angle >= this._options[i].angle && angle <= this._options[i + 1].angle) {
          if (Math.abs(angle - this._options[i].angle) < Math.abs(angle - this._options[i + 1].angle)) {
            return i;
          }
        }
      }
      return i + 1;
    },
    getOffset(el) {
      let top = 0;
      let left = 0;
      let element = el;

      // TODO: Note there is issue if an elements scrollbar
      //       position changes while rotating. But this would
      //       require watching scroll changes or recalculating
      //       offset top on drag. Possible to perhaps recalculate
      //       offset on every tenth drag or something like this
      //       as well.
      do {
        top += (element.offsetTop || 0) - (element.scrollTop || 0);
        left += (element.offsetLeft || 0);

        // NOTE: This is fix for content (scroll type) containers
        //       Seems to not skip a scroll container so we will
        //       check one node immediate below. A bit hacky but
        //       seems to work for now.
        if (
          element.offsetParent
                        && element.offsetParent.childNodes
        ) {
          top -= element.offsetParent.childNodes[0].scrollTop || 0;
        }
        element = element.offsetParent;
      }
      while (element);

      return {
        x: left,
        y: top,
      };
    },
    setAnchorAngle(angle) {
      const distance = Math.abs(this.anchorAngle - angle) / 360;
      this.anchorAngleDistance = distance < this.minSpeed ? this.minSpeed : distance;
      this.anchorAngle = angle;
    },
    processValue(input) {
      let value = parseFloat(input);
      value -= this._options[0].value;
      value /= this._options[this.options.length - 1].value - this._options[0].value;
      value *= this._options[this.options.length - 1].angle - this._options[0].angle;
      value += this._options[0].angle;
      return value;
    },
    processAngle(angle, isAngleChange) {
      let value = null;
      let anchorIndex = null;
      let anchorAngle = this.anchorAngle;
      const angleChange = isAngleChange || Math.abs(angle - anchorAngle) <= this.skipAngle;
      if (Number.isNaN(angle) || angleChange) {
        if (Number.isNaN(angle) || angle < this._options[0].angle) {
          anchorAngle = this._options[0].angle;
        } else if (angle > this._options[this._options.length - 1].angle) {
          anchorAngle = this._options[this._options.length - 1].angle;
        } else {
          anchorAngle = Math.round(angle * 100) / 100;
        }
      }
      if (!this.slider) {
        anchorIndex = this.getIndexActive(anchorAngle);
        this.drag.i = anchorIndex;

        this.setAnchorAngle(anchorAngle);
        if (this._options[this._indexHover]) {
          return this._options[this._indexHover].original;
        }
        return null;
      }

      this.setAnchorAngle(anchorAngle);
      // Get the value first.
      value = ((anchorAngle - this.startAngle) / (this.endAngle - this.startAngle))
        * (this._options[this.options.length - 1].value - this._options[0].value)
        + this._options[0].value;
      // Round to the nearest step.
      value = Math.round(Math.round(value / this.sliderStepBy) * this.sliderStepBy * 100) / 100;
      return value;
    },
    onClickLabel(index) {
      this.toggle(index);

      this.$emit('click');
    },
    onDragStart(event) {
      const $e = event;
      if (this.disabled) {
        return;
      }
      const anchor = this.getOffset(document.getElementById(`knob-dial-anchor-${this._id}`));
      this.drag = {
        x: anchor.x,
        y: anchor.y,
        i: this._index,
      };
      this.isDragging = true;
      // Prevent any highlights or drags.
      $e.stopPropagation();
      $e.preventDefault();

      $e.cancelBubble = true;
      $e.returnValue = false;
      document.addEventListener('mouseup', this.onDragEnd, false);
      document.addEventListener('mousemove', this.onDragMove, false);
      document.addEventListener('touchend', this.onDragEnd, false);
      document.addEventListener('touchmove', this.onDragMove, false);
    },
    onDragMove($e) {
      const sX = this.drag.x;
      const sY = this.drag.y;
      const cX = ($e.touches ? $e.touches[0] : $e).pageX;
      const cY = ($e.touches ? $e.touches[0] : $e).pageY;
      const angle = Math.atan2(cX - sX, -(cY - sY)) * (180 / Math.PI) + 180;
      this.$emit('hover', this.processAngle(angle));
    },
    onDragEnd() {
      let index;
      if (!this.slider) {
        index = this.getIndexActive(this.anchorAngle);
        this.toggle(index);
        if (this._options[index]) {
          this.setAnchorAngle(this._options[index].angle);
        } else {
          this.setAnchorAngle(this._options[0].angle);
        }
      } else {
        this.valueInternal = this.processAngle(this.anchorAngle);
        this.$emit('input', this.valueInternal);
      }
      this.isDragging = false;
      document.removeEventListener('mouseup', this.onDragEnd, false);
      document.removeEventListener('mousemove', this.onDragMove, false);
      document.removeEventListener('touchend', this.onDragEnd, false);
      document.removeEventListener('touchmove', this.onDragMove, false);
    },
  },
};
</script>
