function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title;
  }
  return title;
}

export default {
  mounted() {
    const title = getTitle(this);
    // if (!document.title) {
    //   document.title = this.$t('project.defaultTitle');
    // }
    if (title) {
      document.title = title;
    }
  },
};
