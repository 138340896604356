export const ADD_COLLECTION = 'ADD_COLLECTION';
export const ADD_COLLECTION_SUCCESS = 'ADD_COLLECTION_SUCCESS';
export const ADD_LINKED_RECORD_SUCCESS = 'ADD_LINKED_RECORD_SUCCESS';
export const ADD_FIELD = 'ADD_FIELD';
export const ADD_FIELD_SELECT_OPTION = 'ADD_FIELD_SELECT_OPTION';
export const ADD_RECORD = 'ADD_RECORD';
export const ADD_RECORD_SUCCESS = 'ADD_RECORD_SUCCESS';
export const addTuning = 'addTuning';
export const ADDITIONAL_RECORDS_PROCESSING = 'ADDITIONAL_RECORDS_PROCESSING';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_SUCCESS_MULTI = 'ADD_INVOICE_SUCCESS_MULTI';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_WORKDAY = 'ADD_WORKDAY';
export const ALL_CUSTOMERS = 'ALL_CUSTOMERS';
export const ALL_CUSTOMERS_SUCCESS = 'ALL_CUSTOMERS_SUCCESS';
export const ALL_INVOICES = 'ALL_INVOICES';
export const ALL_INVOICES_SUCCESS = 'ALL_INVOICES_SUCCESS';
export const AUTOSAVE_COMPLETE = 'AUTOSAVE_COMPLETE';
export const AUTOSAVE_REQUEST = 'AUTOSAVE_REQUEST';
export const AUTOSAVE_NOW = 'AUTOSAVE_NOW';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_LOGO = 'CLEAR_LOGO';
export const CLICK_AWAY = 'CLICK_AWAY';
export const CLOCKED_IN = 'CLOCKED_IN';
export const CLOCKED_OUT = 'CLOCKED_OUT';
export const CLOSE_PREFERENCES = 'CLOSE_PREFERENCES';
export const CLOSE_PREFERENCES_CLEAR = 'CLOSE_PREFERENCES_CLEAR';
export const CUSTOMER_BY_ID_SUCCESS = 'CUSTOMER_BY_ID_SUCCESS';
export const CUSTOMER_BY_ID = 'CUSTOMER_BY_ID';
export const DBVERSION_CLEAR = 'DBVERSION_CLEAR';
export const DBVERSION_INC = 'DBVERSION_INC';
export const DBVERSION_MISMATCH = 'DBVERSION_MISMATCH';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_UPLOAD_CLEAR = 'FILE_UPLOAD_CLEAR';
export const GET_COLLECTION = 'GET_COLLECTION';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS';
export const GET_WORKDAYS_SUCCESS = 'GET_WORKDAYS_SUCCESS';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const HIDE_SPINNER_BY_ID = 'HIDE_SPINNER_BY_ID';
export const INITIAL_LOAD = 'INITIAL_LOAD';
export const INITIAL_LOAD_SUCCESS = 'INITIAL_LOAD_SUCCESS';
export const INVOICE_BY_ID = 'INVOICE_BY_ID';
export const INVOICE_BY_ID_SUCCESS = 'INVOICE_BY_ID_SUCCESS';
export const INVOICE_PRINTDATA = 'INVOICE_PRINTDATA';
export const INVOICE_PRINTDATA_SUCCESS = 'INVOICE_PRINTDATA_SUCCESS';
export const INVOICED_ON_ADD = 'INVOICED_ON_ADD';
export const INVOICED_ON_REMOVE = 'INVOICED_ON_REMOVE';
export const INVOICED_ON_SUCCESS = 'INVOICED_ON_SUCCESS';
export const ITEM_REFRESH = 'ITEM_REFRESH';
export const ITEM_REFRESH_DONE = 'ITEM_REFRESH_DONE';
export const LAUNCH_PREVIEW = 'LAUNCH_PREVIEW';
export const LAUNCH_PREVIEW_CLEAR = 'LAUNCH_PREVIEW_CLEAR';
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const LOAD_ACCOUNT_SUCCESS = 'LOAD_ACCOUNT_SUCCESS';
export const LOAD_PREFERENCES = 'LOAD_PREFERENCES';
export const LOAD_PREFERENCES_SUCCESS = 'LOAD_PREFERENCES_SUCCESS';
export const NO_NAME = 'NO_NAME';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const REMOVE_CUSTOMER_SUCCESS = 'REMOVE_CUSTOMER_SUCCESS';
export const REMOVE_INVOICE = 'REMOVE_INVOICE';
export const REMOVE_INVOICE_SUCCESS = 'REMOVE_INVOICE_SUCCESS';
export const REMOVE_RECORD = 'REMOVE_RECORD';
export const REMOVE_RECORD_SUCCESS = 'REMOVE_RECORD_SUCCESS';
export const REMOVE_RECORDS = 'REMOVE_RECORDS';
export const REMOVE_RECORDS_SUCCESS = 'REMOVE_RECORDS_SUCCESS';
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION';
export const REMOVE_COLLECTION_SUCCESS = 'REMOVE_COLLECTION_SUCCESS';
export const REMOVE_TIMECARD_ENTRY = 'REMOVE_TIMECARD_ENTRY';
export const REMOVE_TIMECARD_ENTRY_SUCCESS = 'REMOVE_TIMECARD_ENTRY_SUCCESS';
export const REMOVE_WORKDAY = 'REMOVE_WORKDAY';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_CUSTOMER_PREFERENCES = 'SET_CUSTOMER_PREFERENCES';
export const SET_PREFERENCES_SORT = 'SET_PREFERENCES_SORT';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_INFO_TOAST = 'SHOW_INFO_TOAST';
export const SHOW_ITEM = 'SHOW_ITEM';
export const SHOW_ITEM_CLEAR = 'SHOW_ITEM_CLEAR';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const SHOW_SPINNER_BY_ID = 'SHOW_SPINNER_BY_ID';
export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS';
export const SUBMIT_TIMECARD = 'SUBMIT_TIMECARD';
export const SUBMIT_TIMECARD_SUCCESS = 'SUBMIT_TIMECARD_SUCCESS';
export const TIMECARD_HISTORY = 'TIMECARD_HISTORY';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICES_SUCCESS = 'UPDATE_INVOICES_SUCCESS';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const UPDATE_RECORD_SUCCESS = 'UPDATE_RECORD_SUCCESS';
export const UPDATE_QTY = 'UPDATE_QTY';
export const UPDATE_QTY_SUCCESS = 'UPDATE_QTY_SUCCESS';
export const UPDATE_WORKDAY = 'UPDATE_WORKDAY';
export const ZIP_REQUEST = 'ZIP_REQUEST';
export const ZIP_REQUEST_DONE = 'ZIP_REQUEST_DONE';
export const ZIP_RESULTS = 'ZIP_RESULTS';
export const ZIP_RESULTS_ERROR = 'ZIP_RESULTS_ERROR';
export const chromatic = 'chromatic';
export const arpDuration = 'arpDuration';
export const chordDuration = 'chordDuration';
export const clickIn = 'clickIn';
export const descend = 'descend';
export const displayMode = 'displayMode';
export const hideNoteNames = 'hideNoteNames';
export const instrumentChosen = 'instrumentChosen';
export const leftHand = 'leftHand';
export const keyOf = 'keyOf';
export const mode = 'mode';
export const oscillatorType = 'oscillatorType';
export const referenceTone = 'referenceTone';
export const repeats = 'repeats';
export const repeatTonic = 'repeatTonic';
export const showAllNoteNames = 'showAllNoteNames';
export const showAllNoteNamesWhenPlaying = 'showAllNoteNamesWhenPlaying';
export const spanOctaves = 'spanOctaves';
export const startingOctave = 'startingOctave';
export const synthVolume = 'synthVolume';
export const tuningChosen = 'tuningChosen';
export const letter = 'letter';
export const sharp = 'sharp';
export const flat = 'flat';
export const tuningOptions = 'tuningOptions';
export const adjustTuning = 'adjustTuning';
export const thisTuning = 'thisTuning';
export const stringsData = 'stringsData';
