var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"knob",class:( _obj = {
        'disabled': _vm.disabled,
        'dragging': _vm.isDragging
    }, _obj['knob-' + _vm.size] = _vm.size, _obj['knob-' + _vm.variant] = _vm.variant, _obj ),on:{"click":function($event){return _vm.$emit('click')}}},[_vm._l((_vm._options),function(option,index){return _c('div',{key:index,staticClass:"knob-label-anchor",class:[
            'knob-label-anchor-' + option.angle,
            'knob-label-' + option.labelPosition,
            _vm.getLabelActive(index) ? 'active' : '',
            _vm.getLabelHover(index) ? 'hover' : '' ],style:({
            transform: 'rotate(' + option.angle + 'deg)'
        })},[(option.label !== false)?_c('div',{staticClass:"knob-label",style:({
                transform: 'rotate(-' + option.angle + 'deg)'
            }),on:{"click":function($event){$event.stopPropagation();return _vm.onClickLabel(index)}}},[_c('div',{domProps:{"innerHTML":_vm._s(option.label)}})]):_vm._e()])}),_vm._v(" "),_c('div',{staticClass:"knob-dial",style:({
            transform: 'rotate('  + _vm.anchorAngle + 'deg)',
            '--knob-anchor-angle-transition-distance': _vm.anchorAngleDistance
        }),on:{"mousedown":_vm.onDragStart,"touchstart":_vm.onDragStart}},[_vm._t("dial"),_vm._v(" "),_c('div',{staticClass:"knob-dial-anchor",attrs:{"id":'knob-dial-anchor-' + _vm._id}},[_c('div',{staticClass:"knob-dial-pointer"})])],2),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }